import { FC, useState, useEffect } from "react";
import { Member } from "../../types";
import { Spinner } from "react-bootstrap";
import DemographicDetails from "./DemographicDetails";
import HouseholdContainer from "./HouseholdContainer";
import MembersRegisterComponent from "../../components/MembersComponents/MembersRegisterComponent";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { get } from "../../apiClient";
import { withTranslation } from "react-i18next";

const headers: any = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

type MemberDetailsProps = {
  member: Member;
  isloading: boolean;
  t: any;
};

const MemberDetails: FC<MemberDetailsProps> = ({
  member,
  isloading = false,
  t
}) => {
  const [memberDetails, setMemberDetails] = useState<Member>(member);
  const [isEdit, setEdit] = useState<boolean>(false);
  useEffect(()=> {
    setMemberDetails(member)
  }, [member]);
  if (isloading && member) {
    return <Spinner />;
  }
  const setfarmer = (member: Member, isEdit: boolean): void => {
    setMemberDetails(member);
    setEdit(isEdit);
  }
  const onSubmitEdit = (): void => {
    fetchMember();
    setEdit(false);
  }
  const fetchMember = async () => {
    if (member?.id == undefined) return;
    const response = await get(`members/${member?.id}`, headers);
    setMemberDetails(response?.data)
  };
  return (
    <div>
      {!isEdit ? <div>
        <div style={{marginBottom: '1%'}} className="col-md-4 col-lg-4">
          <Link to="/modules/members/">
            <Button
              label={t("common:back_button")}
              type="button"
              className="p-button-outlined"
            />
          </Link>
        </div>
        <DemographicDetails member={memberDetails} invokeEdit={setfarmer} />
        <div>
          <HouseholdContainer member={member} />
        </div>
      </div> : 
        <div>
          <div className="text-2xl text-primary font-bold mb-3">
              {t("common:edit_label")} {`${member.first_name} ${member.last_name}`}
            </div>
            <div style={{ marginBottom: '1%' }} className="col-md-4 col-lg-4">
            <Button
              label={t("common:back_button")}
              type="button"
              className="p-button-outlined"
              onClick={() => {setEdit(false)}}
            />
          </div>
          <MembersRegisterComponent onSubmit={onSubmitEdit} editData={{ data: memberDetails, is_edit: isEdit }} />
        </div>
      }
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(MemberDetails);

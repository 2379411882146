import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// Main Module Translactions
import MainModuleOptionsEn from "../src/app_modules/nav.json";
import MainModuleOptionsChichewa from "../src/app_modules/nav_chichewa.json";
import MainModuleOptionsTumbuka from "../src/app_modules/nav_tumbuka.json";

// Settings Module Translactions
import SettingsModuleOptionsEng from "../src/app_modules/settings/nav.json";
import SettingsModuleOptionsChichewa from "../src/app_modules/settings/nav_chichewa.json";
import SettingsModuleOptionsTumbuka from "../src/app_modules/settings/nav_tumbuka.json";

// Messaing Module Translactions
import MessagingsModuleOptionsEng from "../src/app_modules/messaging/nav.json";
import MessagingModuleOptionsChichewa from "../src/app_modules/messaging/nav_chichewa.json";
import MessagingModuleOptionsTumbuka from "../src/app_modules/messaging/nav_tumbuka.json";

// Member Module Translactions
import MemberModuleOptionsEng from "../src/app_modules/members/nav.json";
import MemberModuleOptionsChichewa from "../src/app_modules/members/nav_chichewa.json";
import MemberModuleOptionsTumbuka from "../src/app_modules/members/nav_tumbuka.json";
import { AuthProvider } from "./context/AuthContext";

// Advisory Module Translactions
import AdvisoryModuleOptionsEng from "../src/app_modules/advisory_services/nav.json";
import AdvisoryModuleOptionsChichewa from "../src/app_modules/members/nav_chichewa.json";
import AdvisoryModuleOptionsTumbuka from "../src/app_modules/members/nav_tumbuka.json";

// Reports Module Translactions
import ReportsModuleOptionsEng from "../src/app_modules/reports/nav.json";
import ReportsModuleOptionsChichewa from "../src/app_modules/reports/nav_chichewa.json";
import ReportsModuleOptionsTumbuka from "../src/app_modules/reports/nav_tumbuka.json";
import PageLoader from "./loaders/PageLoader";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
// interpolation: { escapeValue: false },
// resources: {
//   english: {
//     main_module_nav: MainModuleOptionsEn,
//     settings_module: SettingsModuleOptionsEng,
//     messaging_module: MessagingsModuleOptionsEng,
//     members_module: MemberModuleOptionsEng,
//     advisory_module: AdvisoryModuleOptionsEng,
//     reports_module: ReportsModuleOptionsEng,
//   },
//   chichewa: {
//     main_module_nav: MainModuleOptionsChichewa,
//     settings_module: SettingsModuleOptionsChichewa,
//     messaging_module: MessagingModuleOptionsChichewa,
//     members_module: MemberModuleOptionsChichewa,
//     advisory_module: AdvisoryModuleOptionsChichewa,
//     reports_module: ReportsModuleOptionsChichewa,
//   },
//   tumbuka: {
//     main_module_nav: MainModuleOptionsTumbuka,
//     settings_module: SettingsModuleOptionsTumbuka,
//     messaging_module: MessagingModuleOptionsTumbuka,
//     members_module: MemberModuleOptionsTumbuka,
//     advisory_module: AdvisoryModuleOptionsTumbuka,
//     reports_module: ReportsModuleOptionsTumbuka,
//   },
// },
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <AuthProvider>
        <Suspense fallback={<PageLoader />}>
          <App />
        </Suspense>
      </AuthProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

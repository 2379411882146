export const APP_MODULES = [
  {
    dicon: "pi pi-user-plus",
    iconColor: "#FF5733",
    name: "e_registration_module",
    to: "modules/members",
    icon: "members-3.png",
    description: "e_registration_description",
    objectiveOne: "e_reg_obj_one",
    objectiveTwo: "e_reg_obj_two",
    objectiveThree: "e_reg_obj_three",
    iconName: "pi-users",
  },
  {
    dicon: "pi pi-verified",
    iconColor: "#6C5CE7",
    name: "agriculture_advisories_module",
    to: "modules/advisories",
    icon: "members-3.png",
    description: "agriculture_advisory_description",
    objectiveOne: "agr_ad_obj_one",
    objectiveTwo: "agr_ad_obj_two",
    objectiveThree: "agr_ad_obj_three",
    iconName: "pi-users",
  },
  {
    dicon: "pi pi-user",
    iconColor: "#1DD1A1",
    name: "extention_worker_module",
    to: "modules/members",
    icon: "members-3.png",
    description: "extension_worker_description",
    objectiveOne: "ex_worker_obj_one",
    objectiveTwo: "ex_worker_obj_two",
    objectiveThree: "ex_worker_obj_three",
    iconName: "pi-users",
  },
  {
    dicon: "pi pi-chart-bar",
    iconColor: "#F79F1F",
    name: "reports_module",
    to: "modules/reports",
    icon: "reports.png",
    description: "reports_description",
    objectiveOne: "reports_obj_one",
    objectiveTwo: "reports_obj_two",
    objectiveThree: "reports_obj_three",
    iconName: "pi-chart-line",
  },
  {
    dicon: "pi pi-comments",
    iconColor: "#3498DB",
    name: "messaging_module",
    to: "modules/messaging",
    icon: "message-2.png",
    description: "messaging_description",
    objectiveOne: "message_obj_one",
    objectiveTwo: "message_obj_two",
    objectiveThree: "message_obj_three",
    objectiveFour: "Received Messages",
    iconName: "pi-inbox",
  },
  {
    dicon: "pi pi-cog",
    iconColor: "#E74C3C",
    name: "settings_module",
    to: "modules/settings",
    icon: "settings-3.png",
    description: "settings_description",
    objectiveOne: "settings_obj_one",
    objectiveTwo: "settings_obj_two",
    objectiveThree: "settings_obj_three",
    iconName: "pi-cog",
  },
];

import { Button } from "react-bootstrap";
import styles from "./ActionButtons.module.css";
import { FC } from "react";
import { withTranslation } from "react-i18next";

type ActionButtonsProps = {
  update?: () => void;
  remove?: () => void;
  t: any;
};

const ActionButtonsComponents: FC<ActionButtonsProps> = ({
  update,
  remove,
  t
}: any): any => {
  return (
    <div className={styles.container}>
      <Button
        variant="primary"
        type="submit"
        className={styles.actionButton}
        onClick={update}
      >
        <i className="pi pi-save mr-2"></i>
        {t("common:save_label")}
      </Button>
      <Button
        variant="danger"
        type="submit"
        className={styles.actionButton}
        onClick={remove}
      >
        <i className="pi pi-times  mr-2"></i>
        {t("common:delete_label")}
      </Button>
    </div>
  );
};
export default withTranslation(["common","advisories"])(ActionButtonsComponents);

import React from 'react';
import GeoLocationBaseComponent from './MapBaseComponent';
import { MapConfig } from './MapConfig';
import { Area } from "../../../types";
interface MapLocationProps {
    config: MapConfig;
    mark_value?: MapMarkStatus | null;
    location_area?: LocationArea;
    invokeCancelMapMarker?: (isCancel: boolean) => void;
}

interface LocationArea {
    district: Area;
    ta: Area;
    village: Area;
    fullname: string;
}

enum MapMarkStatus {
    Marked = 'success',
    Pending = 'warning',
    Unmarked = 'danger'
}

const MapProvider: React.FC<MapLocationProps> = ({ config, mark_value, location_area, invokeCancelMapMarker }) => {
    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GeoLocationBaseComponent config={config} mark_value={mark_value} location_area={location_area} invokeCancelMapMarker={invokeCancelMapMarker} />
        </div>
    );
};

export default MapProvider;

import React, { FC } from "react";
import { DistrictReportData } from "../types";
import { HOME_PAGE_STATS } from "../constants/homePageStats";
import { withTranslation } from "react-i18next";

interface HomePageStatsProps {
  t: any;
  data: DistrictReportData;
}

const HomePageStats: FC<HomePageStatsProps> = ({data, t}) => {
  
  const stats = HOME_PAGE_STATS;
  return (

    <div className="row m-auto">
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
        <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round bg-blue-100 Box">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-bold mb-3 dash-stats-name">
                {t(stats.district_coverage)}
              </span>
              <div className="text-900 font-medium text-xl dash-stats">
                {data.coverage}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-map-marker text-blue-500 fs-3"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
        <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round bg-orange-100 Box">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-bold mb-3 dash-stats-name">
                {t(stats.farmer_organizations)}
              </span>
              <div className="text-900 font-medium text-xl dash-stats">
                {data.organisations}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-users text-orange-500 fs-3"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
        <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round bg-cyan-100 Box">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-bold mb-3 dash-stats-name">
                {t(stats.farmers_registered)}
              </span>
              <div className="text-900 font-medium text-xl dash-stats">
                {data.farmers}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-user text-cyan-500 fs-3"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
        <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round bg-purple-100 Box">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-bold mb-3 dash-stats-name">
                {t(stats.extension_workers)}
              </span>
              <div className="text-900 font-medium text-xl dash-stats">
                {data.extension_workers}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-purple-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-briefcase text-purple-500 fs-3"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("main")(HomePageStats);

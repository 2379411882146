import React, { Fragment, useEffect, useState } from "react";

import { BreadCrumb } from "primereact/breadcrumb";
import BarChart, {
  BarChartProps,
} from "../../components/ReportComponents/BarChart";
import { api } from "../../apiClient";
import { DistrictData, DistrictReportData } from "../../types";
import { withTranslation } from "react-i18next";
import MalawiMap from "./MalawiMap";
interface ReportsAppModuleProps {
  t: any;
}

const ReportsAppModule: React.FC<ReportsAppModuleProps> = ({t}) => {
  const [reportData, setReportData] = useState<DistrictReportData>();
  const [barGraphData, setBarGraphData] = useState<BarChartProps>();
  

  const items = [
    { label: "Reports" },
    { label: "Market Data", url: "#" },
    { label: "Geo Data", url: "#" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  //   // Sample data for the bar chart with two bars per district (Farmers & Organizations)
  //   const barChartData = {
  //     labels: ["Balaka", "Kasungu", "Mzimba"],
  //     farmersData: [15, 35, 78], // TODO: get from API Number of farmers per district
  //     institutionsData: [2, 3, 7], // TODO: get from API Number of institutions per district
  //   };

  useEffect(() => {
    const fetchStats = () => {
      api.get("/reports").then((response) => {
        setReportData(response.data);
        const data = response.data;
        const labels = [
          ...new Set(data.data.map((item: DistrictData) => item.district)),
        ];
        const barGraphData = {
          labels: labels,
          farmersData: data.data
            .filter((i: DistrictData) => i.type === "Farmer")
            .map((item: DistrictData) => item.count),
          institutionsData: data.data.map(
            (item: DistrictData) => item.organisationCount
          ),
        };
        setBarGraphData(barGraphData);
      });
    };
    fetchStats();
  }, []);
  return (
    <div className="app">
      <div className="main-content p-5 silver-line">
        <BreadCrumb model={items} home={home} />
        <div className="surface-0">
          <div className="surface-0">
            <div className="text-3xl font-medium text-900 mb-3">
              <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                  <div className="text-6xl text-primary font-bold mb-3">
                    {t("reports:reports_header")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="surface-0">
            {reportData && (
              <div className="row surface-0 text-800">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
                  <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round Box">
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-500 font-bold mb-3 dash-stats-name">
                          {reportData.coverage}
                        </span>
                        <div className="text-900 font-medium text-xl dash-stats">
                          {t("common:district_coverage")}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center border-round"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                      >
                        <i className="pi pi-users text-blue-500 fs-3"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
                  <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round Box">
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-500 font-bold mb-3 dash-stats-name">
                          {reportData.organisations}
                        </span>
                        <div className="text-900 font-medium text-xl dash-stats">
                          {t("common:farmer_organizations")}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center border-round"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                      >
                        <i className="pi pi-users text-blue-500 fs-3"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
                  <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round Box">
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-500 font-bold mb-3 dash-stats-name">
                          {reportData.farmers}
                        </span>
                        <div className="text-900 font-medium text-xl dash-stats">
                          {t("common:farmers_registered")}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center border-round"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                      >
                        <i className="pi pi-users text-blue-500 fs-3"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-2">
                  <div className="surface-0 shadow-2 p-4 border-1 border-50 border-round Box">
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-500 font-bold mb-3 dash-stats-name">
                          {reportData.extension_workers}
                        </span>
                        <div className="text-900 font-medium text-xl dash-stats">
                          {t("common:extension_workers")}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center border-round"
                        style={{ width: "2.5rem", height: "2.5rem" }}
                      >
                        <i className="pi pi-users text-blue-500 fs-3"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr />
            <div className="row surface-0 text-800">
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mt-2">
                <div className="flex justify-content-between mb-3">
                  <MalawiMap />
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12 mt-2">
                <div className="row surface-0 text-800">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                    <div className="flex justify-content-between mb-3">
                      {barGraphData && (
                        <BarChart
                          farmersData={barGraphData.farmersData}
                          institutionsData={barGraphData.institutionsData}
                          labels={barGraphData.labels}
                        />
                      )}
                    </div>
                  </div>
                  {/*<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">*/}
                  {/*    <div className="flex justify-content-between mb-3">*/}
                  {/*        <BarChart*/}
                  {/*            farmersData={barChartData.farmersData}*/}
                  {/*            institutionsData={barChartData.institutionsData}*/}
                  {/*            labels={barChartData.labels}*/}
                  {/*        />*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["reports", "common"])(ReportsAppModule);

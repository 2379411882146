import "../../App.css";
import { FC } from "react";
import { User } from "../../context/types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import UserActionBtn from "./Components/UserActionBtn";

type UserContainerProps = {
  users: User[],
}

const UserList: FC<UserContainerProps> = ({users}) => {

  const Actions = (user: User) => {
    return  <UserActionBtn user={user} />
  }

  const columns = [
    {
      field: 'first_name',
      header: 'First Name',
    },
    {
      field: 'last_name',
      header: 'Last Name',
    },
    {
      field: 'email',
      header: 'Email',
    },
    {
      body: Actions,
      header: 'Actions',
    }
  ]

  return (
    <div>
      <div className="p-5 silver-line">
        <div>
          <h1 className="text-4xl font-medium text-900 mb-3">Users</h1>
          <div className="font-medium text-500 mb-3">
            <i className="pi pi-users mr-2"></i>
            <span>{users.length}</span>
          </div>

          <div className="mt-5">
            <DataTable value={users}>
              {columns.map((col, i) => (
                <Column 
                  key={col.field}
                  field={col.field}
                  body={col.body}
                  header={col.header}
                >
                </Column>
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;

import "./styles.css";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { get, put } from "../../apiClient";
import ArticlesComponent from "../../components/ArticlesComponents/ArticlesComponent";
import ActionButtonsComponents from "../../components/AdvisoryServicesComponents/ActionButtonsComponents";
import { Dialog } from "primereact/dialog";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { ActionOption, Category, Crumb } from "./types";
import { BreadCrumb } from "primereact/breadcrumb";
import CreatArticleModule from "../advisory_services/articles/create";
import CategoryCard from "./CategoryCard";
import ArticleActionButton from "./components/ArticleActionButton";
import { Skeleton } from "primereact/skeleton";
interface Article {
  id?: number;
  page_number: number | 0;
  content: string | '';
  title?: string;
  author?: Author
  is_update?: boolean
}
interface Author {
  first_name: string;
  last_name: string;
}

const Read = ({ option = ActionOption.Categories, t }: any) => {
  const { id }: any = useParams();
  const headers: any = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const defaultCategory: Category = {};
  const defaultArticle: Article = { page_number: 0, content:'', is_update: false }
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [categoryName, setCategoryName] = useState<string>("");
  const [category, setCategory] = useState<Category>(defaultCategory);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 1; // Example number of items per page
  const [articles, setArticles] = useState<Array<string>>([]);
  const [article, setArticle] = useState<any>({})
  const [articleObjects, setArticleObjects] = useState<Article[]>([]);
  const [filteredArticles, setFilteredArticles] = useState<Article[]>([])
  const [viewOption, setViewOption] = useState<ActionOption>(
    ActionOption.Categories
  );
  const [isEditArticle, setEditArticle] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Crumb[]>([
    {
      label: "Advisories",
      url: "/modules/advisories",
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [actionButtonsVisible, setActionButtonsVisible] =
    useState<boolean>(false);

  const handleCheckboxChange = (data: any): void => {
    setActionButtonsVisible(true);
    setCategoryName(data.name);
    setCategory(data);
  };
  const handleRefresh = (): void => {
    setRefresh(!refresh);
  };
  useEffect(() => {
    fetchCategories();
    fetchArticlesById();
    setViewOption(option);
  }, [refresh]);
  const fetchCategories = async (): Promise<void> => {
    setIsLoading(true)
    const isParent: boolean = id === undefined;
    const Id = isParent ? "" : id;
    const response: any = await get(`categories/${Id}`, headers);
    const data: any = response.data;
    setCategoryName(data.name);
    const list: Array<Category> = isParent ? data : data?.children;
    setCategories(list);
    setIsLoading(false)
    setBreadcrumbs([
      ...breadcrumbs,
      { label: data.name, url: `/modules/advisories/read/${data.id}` },
    ]);
  };
  async function fetchArticlesById(): Promise<void> {
    const response: any = await get(`categories/${id}/articles`, headers);
    const blogs: Array<Article> = response.data;
    setArticleObjects(blogs)
    setFilteredArticles(blogs);
    const blogStrings: Array<string> = blogs
      .map((blog: Article) => blog)
      .sort((a: Article, b: Article) => {
        return a.page_number - b.page_number;
      })
      .map((item): string => {
        return item.content;
      });
    setArticles(blogStrings);
  }
  const handlePageChange = useCallback((page: any) => {
    setCurrentPage(page);
  }, []);

  const handleCategoryUpdate = async (): Promise<void> => {
    const payload: Category = {
      id: category.id,
      name: categoryName,
      image: selectedImage,
    };
    await put("categories", headers, payload.id, payload);
    setActionButtonsVisible(false);
    fetchCategories();
    fetchArticlesById();
  };
  const handleCategoryDelete = () => {
  };
  const handleImageChange = (event: any): void => {
    const file = event.target.files[0];
    if (file) {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
    }
  };
  const handleNameChange = (event: any): void => {
    setCategoryName(event.target.value);
  };
  const handleViewOption = (option: ActionOption): void => {
    setViewOption(option);
  };
  const Categories = categories?.map((data: any) => {
    return (
      <CategoryCard
        key={data.id}
        data={data}
        handleCheckboxChange={(data) => handleCheckboxChange(data)}
        allowRefresh={handleRefresh}
      />
    );
  });
  const handleArticleCreatedNotification = (isCreated: boolean): void => {
    if (isCreated) {
      fetchArticlesById();
      setViewOption(ActionOption.Categories);
    }
  }
  const invokeEditArticle = (data: any) => {
    setViewOption(ActionOption.UpdateArticle);
    setEditArticle(data.is_edit);
    setArticle({ ...data.content, is_update: true });
  }

  const handleSearchArticle = (searchTerm: any) => {
    const filteredArticles: Article[] = articleObjects.filter((article: Article) => {
      return article?.title?.toLowerCase()?.includes(searchTerm.target.value.toLowerCase())
    }, []);
    setFilteredArticles(filteredArticles)
    articlesToArrayOfStrings(filteredArticles)
    searchTerm.target.value === '' ? fetchArticlesById() : null;
  }

  const articlesToArrayOfStrings = (articles: Article[]) => {
    const blogStrings: Array<string> = articles
      .map((blog: Article) => blog)
      .sort((a: Article, b: Article) => {
        return a.page_number - b.page_number;
      })
      .map((item): string => {
        return item.content;
      });
    setArticles(blogStrings);
  }

  const filterArticleById = (data: any) => {
    const articles = filteredArticles.filter((article: any) => {
      return article.id === data.id;
    });
    setArticleObjects(articles);
  }

  return (
    <div className="p-4 silver-line">
      <BreadCrumb model={breadcrumbs} home={{ icon: "pi pi-home" }} />

      <div className="row m-3">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <ArticleActionButton
                category={category}
                handleViewOption={handleViewOption}
              />
            </div>
            {viewOption == ActionOption.Categories && Categories.length > 0 && (
              <div className="col-md-12 card" style={{ marginTop: '1%' }}>
                <div className="row">
                  <div className="row t-4">
                    <h4> {t("advisories:categories_label")} </h4>
                    <hr />
                    {isLoading ?
                      (
                        <div>
                          <Skeleton height="15rem" className="mb-2" borderRadius="16px"></Skeleton>
                        </div>
                      ) : Categories
                    }  
                  </div>
                </div>
              </div>
            )}

            { viewOption == ActionOption.Categories ?
            <div className="col-md-12 card mt-3">
              <div className="ms-2">
                <h4> {t("advisories:content_label")} </h4>
              </div>
              <hr />
              <div className="flex">
                <div className="p-2 title-group">
                  <Form.Group className="w-100">
                    <Form.Control
                      type="text"
                      name="email"
                      className="p-2"
                      onChange={handleSearchArticle}
                      placeholder={`${t("common:search_label")}...`}
                    />
                  </Form.Group>
                  <div className="mt-3">
                    {filteredArticles.map((article, index) => (
                      <div className="fw-bolder" onClick={()=> filterArticleById(article)}>
                        <p className="article-title" key={index}>{article.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="divider"></div>
                <div className="content-container p-2 w-100">
                  <ArticlesComponent
                    notes={articles}
                    articles={articleObjects}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    currentNotes={[]}
                    onPageChange={handlePageChange}
                    notifyEdit={invokeEditArticle}
                  />
                </div>
              </div>
              
              
            </div> : ''}
            {viewOption == ActionOption.CreateArticle ? (
              <div className="col-md-12">
                <CreatArticleModule notifyOnCreated={handleArticleCreatedNotification} />
              </div>
            ) : (
              ""
            )}
            {viewOption == ActionOption.UpdateArticle ? (
              <div className="col-md-12">
                <CreatArticleModule notifyOnCreated={handleArticleCreatedNotification} isEdit={isEditArticle} article={article} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Dialog
          header={category.name}
          visible={actionButtonsVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setActionButtonsVisible(false);
          }}
        >
          <div className="card">
            <div className="row">
              <div className="col-md-12">
                <Form className="login-form">
                  <Form.Group>
                    <Form.Label> {t("advisories:category_name")} </Form.Label>
                    <Form.Control
                      type="text"
                      name="category_name"
                      value={categoryName}
                      className="input"
                      onChange={handleNameChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label> {t("advisories:category_image")} </Form.Label>
                    <div className="row">
                      <div className="col-md-10">
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="category_image"
                          className="input"
                          onChange={handleImageChange}
                        />
                      </div>
                      <div className="col-md-2">
                        {selectedImage && (
                          <img
                            src={selectedImage}
                            alt="Selected"
                            style={{
                              width: "100%",
                              height: "50px",
                              marginTop: "0px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          <ActionButtonsComponents
            update={handleCategoryUpdate}
            remove={handleCategoryDelete}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default withTranslation(["advisories", "common"])(Read);

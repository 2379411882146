import DaesLogo from "../NavComponents/daes-logo.png";
import MalawiLogo from "../NavComponents/malawi-gov.png";
import './footer.css';

const Footer  = () => {
  return  <>
    <div className="footer flex justify-content-between mt-4" style={{backgroundColor: '#EDEDED'}}>
      <img src={DaesLogo} alt="logo" height="55" />
      <div style={{alignSelf: 'center'}}>
      Powered by MoA Department of Exension Services (DAES) with support from FAO through the Digital Villages Initiative (DVI)
      </div>
      <img src={MalawiLogo} alt="logo" height="40" />
    </div>
  </>
}

export default Footer
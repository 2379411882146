import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import {
  HouseholdMember,
  Household as HouseholdType,
  Member,
} from "../../types";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import styles from "./Household.module.css";
import HouseholdMemberForm from "./HouseholdMemberForm";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { withTranslation } from "react-i18next";

type HouseholdProps = {
  member: Member;
  household: HouseholdType;
  members: HouseholdMember[];
  handleCreateHousehold: () => void;
  setHousehold: Dispatch<SetStateAction<HouseholdType>>;
  handleCreateHouseholdMember: (params: any) => void;
  handleDeleteMember: (member: HouseholdMember) => void;
  handleUpdateMember: (id: string | number, params: any) => void;

  loading: boolean;
  t: any;
};

const Household: FC<HouseholdProps> = ({
  member,
  household,
  members,
  loading,
  handleDeleteMember,
  t,
  ...rest
}) => {
  const [showCreateHousehold, setShowCreateHousehold] =
    useState<boolean>(false);
  const [openMemberForm, setOpenMemberForm] = useState<boolean>(false);
  const [memberToUpdate, setMemberToUpdate] = useState<HouseholdMember>();
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  return (
    <div>
      <ConfirmDialog
        rejectClassName="p-button-danger rounded"
        acceptClassName="p-button-info rounded"
        footer={({ element }) => {
          return <div className="flex gap-2">{element}</div>;
        }}
      />
      {loading ? (
        <ProgressSpinner />
      ) : (
        <div>
          <div className="col-4">
            {!household.id ? (
              <Button
                className="rounded"
                style={{ width: "auto", padding: "20px" }}
                onClick={() => setShowCreateHousehold(true)}
              >
                <i className="pi pi-plus mr-2"></i>
                {t("eRegistration:create_household")}
              </Button>
            ) : (
              <Button
                onClick={() => setOpenMemberForm(true)}
                className="rounded"
                severity="info"
                style={{ width: "auto", padding: "20px" }}
              >
                <i className="pi pi-plus mr-2"></i>
                {t("eRegistration:create_new_member")}
              </Button>
            )}
          </div>

          <div className={styles.chipContainer}>
            {members.map((member) => {
              return (
                <div className={styles.chip} key={member.id}>
                  <div
                    style={{
                      position: "absolute",
                      color: "white",
                      padding: "5px",
                      top: -10,
                      right: -8,
                      zIndex: 1000,
                    }}
                  >
                    <i
                      className="pi pi-times rounded-circle bg-danger tex-light p-1 "
                      onClick={() => {
                        confirmDialog({
                          message: `${t("common:caution_message_proceed")}`,
                          header: `${t("common:caution_header")}`,
                          icon: "pi pi-exclamation-triangle",
                          defaultFocus: `${t("common:accept_button")}`,
                          accept: () => handleDeleteMember(member),
                        });
                      }}
                    ></i>
                  </div>
                  <div
                    onClick={() => {
                      setMemberToUpdate(member);
                      setOpenMemberForm(true);
                      setUpdateMode(true);
                    }}
                  >
                    <i className="pi pi-user"></i>
                    <h3>{member.name}</h3>
                    <hr />
                    <div className={styles.hmemberDetails}>
                      <p className="font-semibold">
                        {t("common:sex_label")}:{" "}
                        <span className="text-sm font-normal">
                          {member.gender}
                        </span>
                      </p>
                      <p className="font-semibold">
                        {t("common:relation_label")}:{" "}
                        <span className="text-sm font-normal">
                          {member.relationship}
                        </span>
                      </p>
                      <p className="font-semibold">
                        {t("common:national_id_label")}:{" "}
                        <span className="text-sm font-normal">
                          {member.national_id}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <CreateHousehold
            visible={showCreateHousehold}
            household={household}
            setVisible={setShowCreateHousehold}
            member={member}
            trans={t}
            {...rest}
          />

          <HouseholdMemberForm
            visible={openMemberForm}
            setVisible={setOpenMemberForm}
            update={updateMode}
            member={memberToUpdate}
            {...rest}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(Household);

const CreateHousehold: FC<{
  visible: boolean;
  setVisible: (v: boolean) => void;
  setHousehold: Dispatch<SetStateAction<HouseholdType>>;
  handleCreateHousehold: () => void;
  household: HouseholdType;
  member: Member;
  trans: any
}> = ({
  visible,
  household,
  setHousehold,
  setVisible,
  handleCreateHousehold,
  member,
  trans
}) => {
  const handleChange = (e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;

    setHousehold((prev: HouseholdType) => ({
      ...prev,
      [name]: value,
    }));
  };

  const footerContent = (
    <div className="flex gap-2 justify-content-end">
      <Button
        label="No"
        size="small"
        style={{ width: "100px", borderRadius: "5px" }}
        icon="pi pi-times"
        security="danger"
        severity="danger"
        rounded
        onClick={() => setVisible(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        size="small"
        style={{ width: "100px", borderRadius: "5px" }}
        onClick={() => {
          handleCreateHousehold();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );
  return (
    <Dialog
      draggable={false}
      header={`${trans("eRegistration:create_household")}`}
      visible={visible}
      style={{ width: "35vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
    >
      <div className="">
        <div className="flex flex-column gap-1">
          <label htmlFor="household_name"> {trans("common:name")} </label>
          <InputText
            className="p-inputtext-sm"
            name="name"
            onChange={handleChange}
            value={household.name}
            placeholder={`${member.first_name}'s household`}
          />
        </div>
        <div className="flex flex-column gap-1 mt-2">
          <label htmlFor="serial_number"> {trans("common:serial_number")} </label>
          <InputText
            className="p-inputtext-sm"
            name="serial_number"
            keyfilter="int"
            onChange={handleChange}
            value={household.serial_number}
          />
        </div>
      </div>
    </Dialog>
  );
};

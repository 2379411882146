import { api } from "../apiClient";

export const getMemberById = async (id?: string | number) => {
  const response = await api.get(`/members/${id}`);
  return response.data;
};

export const getHouseholdMembers = async (id?: string | number) => {
  const response = await api.get(`/households/${id}/members`);
  return response.data;
};

export const createHousehold = async (params: any) => {
  const response = await api.post(`/households`, params);
  return response.data;
};

export const createHouseholdMember = async (
  id?: string | number,
  params?: any
) => {
  const response = await api.post(`/households/${id}/members`, params);
  return response.data;
};

export const getUsersList = async () => {
  const response = await api.get("users");
  return response.data;
};

export const deleteHousehouldMember = async (id?: string | number) => {
  const response = await api.delete(`/households/${id}/members`);
  return response.data;
};

export const updateHouseholdMember = async (
  id: string | number,
  params: any
) => {
  const response = await api.patch(`/households/${id}/members`, params);
  return response.data;
};

export const getPosts = async (offset: number = 0) => {
  const response = await api.get(`/posts?offset=${offset}`);
  return response.data;
};

export const getPostComments = async (
  id?: string | number,
  offset: number = 0
) => {
  const response = await api.get(`/posts/${id}/comments?offset=${offset}`);
  return response.data;
};

export const createPost = async (params: any) => {
  const response = await api.post(`/posts`, params);
  return response.data;
};

export const deletePost = async (id: string | number) => {
  const response = await api.delete(`/posts/${id}`);
  return response.data;
};

import { FC, useState } from "react";
import style from "../style.module.css";
import { User } from "../../../context/types";
import "../../messaging/messaging.css";

type UserActionBtnProp = {
  user: User,
};

const UserActionBtn: FC<UserActionBtnProp> = ({user}) => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return <div>
      <button className={`${style.actionButton} ${style.blueColor}`} onClick={openModal}>
        <i className="pi pi-pencil"></i>
      </button>
      <button className={`${style.actionButton} ${style.redColor}`}>
        <i className="pi pi-trash"></i>
      </button>

      {showModal && (
        <div className="model" onClick={closeModal}>
          <div className="model-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="pt-3 pb-3">
              <h3 className="text-lg">Do Something To {user.first_name} {user.last_name} </h3>
            </div>

            <div className="flex">
              <button
                type="button"
                className="btn btn-info me-1"
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger ms-1"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
  </div>
}

export default UserActionBtn;
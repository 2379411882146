import { Form } from "react-bootstrap";
import { useContext, useImperativeHandle, forwardRef, useState, useEffect } from "react";
import "./DemographicsComponent.styles.css";
import { RegistrationContext } from "../../MembersComponents/MembersRegisterComponent";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { withTranslation } from "react-i18next";

const DemographicsComponent = forwardRef(({ onChange, t }: any, ref: any) => {
  const data: any = useContext(RegistrationContext);
  const [errors, setErrors]: any = useState({});
  const [dateOfBirth, setDateOfBirth] = useState<any>(undefined);

  useEffect(()=>{
    const date: any = data.dob == undefined ? data?.dob : new Date(data?.dob);
    setDateOfBirth(date)
  }, [])

  const handleInputChange = async (event: any) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const validateFrom = () => {
    const newErrors: any = {};

    if (!data?.first_name?.trim()) {
      newErrors.first_name = `${t("common:first_name_required")}`;
    }

    if (!data?.last_name?.trim()) {
      newErrors.last_name = `${t("common:last_name_required")}`;
    }

    if (!data?.gender?.trim()) {
      newErrors.gender = `${t("common:gender_required")}`;
    }

    if (!data?.dob) {
      newErrors.dob = `${t("common:dob_required")}`;
    }

    if (!data?.national_id?.trim()) {
      newErrors.national_id = `${t("common:national_id_required")}`;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useImperativeHandle(ref, () => ({
    validateFrom,
  }));

  function formatDate(date: any) {
    const toDate = new Date(date);
    return toDate?.toLocaleDateString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return (
    <Form.Group>
      <Form.Label className="form-labels">{t("common:first_name")} *</Form.Label>
      <Form.Control
        type="text"
        size="sm"
        name="first_name"
        className="input pullErrorMessage"
        value={data?.first_name}
        onChange={handleInputChange}
      />
      <p className="errorMessage">
        {errors.first_name && <span>{errors.first_name}</span>}
      </p>
      <Form.Label className="form-labels">{t("common:last_name")} *</Form.Label>
      <Form.Control
        type="text"
        name="last_name"
        className="input pullErrorMessage"
        value={data?.last_name}
        onChange={handleInputChange}
      />
      <p className="errorMessage">
        {errors.last_name && <span>{errors.last_name}</span>}{" "}
      </p>

      <Form.Label className="form-labels">{t("common:gender")} *</Form.Label>
      <div className="card flex justify-content-center pullErrorMessage">
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId="ingredient1"
              name="gender"
              value="female"
              onChange={handleInputChange}
              checked={data?.gender === "female"}
            />
            <label htmlFor="ingredient1" className="ml-2">
            {t("common:female_label")}
            </label>
          </div>
          <div className="flex align-items-center">
            <RadioButton
              inputId="ingredient2"
              name="gender"
              value="male"
              onChange={handleInputChange}
              checked={data?.gender === "male"}
            />
            <label htmlFor="ingredient2" className="ml-2">
            {t("common:male_label")}
            </label>
          </div>
        </div>
      </div>
      <p className="errorMessage">
        {errors.gender && <span>{errors.gender}</span>}{" "}
      </p>
      <hr />

      <Form.Label className="form-labels">{t("common:date_of_birth")} *</Form.Label>
      <Calendar
        className="input pullErrorMessage "
        value={dateOfBirth}
        onChange={(e: any) =>
          handleInputChange({ target: { name: "dob", value: e.value } })
        }
        name="dob"
        dateFormat="dd/mm/yy"
      />
      <p className="errorMessage">
        {" "}
        {errors.dob && <span>{errors.dob}</span>}{" "}
      </p>
      <hr />

      <Form.Label className="form-labels">{t("common:national_id_label")}*</Form.Label>
      <Form.Control
        type="text"
        name="national_id"
        className="input"
        value={data?.national_id}
        onChange={handleInputChange}
      />
      <p className="errorMessage">
        {errors.national_id && <span>{errors.national_id}</span>}
      </p>
    </Form.Group>
  );
});

export default withTranslation(["eRegistration", "common"])(DemographicsComponent);

import { FC, useCallback, useEffect, useState } from "react";
import Comment from "./Comment";
import { ForumPost, ForumComment, Pagination } from "../../types";
import { api } from "../../apiClient";
import { useAuth } from "../../context/AuthContext";
import { getPostComments } from "../endpoints";
import { PaginatorPageChangeEvent } from "primereact/paginator";

type CommentContainerProps = {
  post: ForumPost;
  setCommentsCount: (count: number) => void;
};

const CommentContainer: FC<CommentContainerProps> = ({
  post,
  setCommentsCount,
}) => {
  const { user } = useAuth();
  const [comments, setComments] = useState<ForumComment[]>([]);
  const [pagination, setPagination] = useState<Pagination>();

  const [commentsLoading, setCommentsLoading] = useState<boolean>(false);

  const handleAddComment = (comment: string) => {
    // add comment
    api
      .post(`/posts/${post.id}/comments`, { comment })
      .then((response) => {
        setComments([response.data, ...comments]);
        setCommentsCount(comments.length + 1);
      })
      .catch((error) => {});
  };

  const handleOnPageChange = (event: PaginatorPageChangeEvent) => {
    handleCommentsFetch(event.page * event.rows);
  };

  const handleCommentsFetch = (offset: number = 0) => {
    setCommentsLoading(true);
    getPostComments(post.id, offset)
      .then((response) => {
        setCommentsLoading(false);
        const limit = response.limit;
        const count = response.count;
        const offset = response.offset;
        setPagination({ limit, count, offset });
        setComments(response.data);
      })
      .catch((error) => {
        setCommentsLoading(false);
      });
  };

  useEffect(() => {
    // fetch comments
    handleCommentsFetch();
  }, []);

  if (commentsLoading || !user || !pagination) {
    return <div>Loading...</div>;
  }

  return (
    <Comment
      comments={comments}
      user={user}
      hanleAddComment={handleAddComment}
      pagination={pagination}
      handleOnPageChange={handleOnPageChange}
    />
  );
};

export default CommentContainer;

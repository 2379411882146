export const REGISTER_ACTIONS = [
  {
    label: "common:registration_button",
    icon: "pi pi-plus",
    severity: "success",
    url: "/modules/members/create",
  }
];

export const GENDER_OPTIONS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const RELATIONSHIP_OPTIONS = [
  {
    label: "Spouse",
    value: "spouse",
  },
  {
    label: "Sibling",
    value: "sibling",
  },
  {
    label: "Cousin",
    value: "cousin",
  },
  {
    label: "Child",
    value: "child",
  },
  {
    label: "Friend",
    value: "friend",
  },
];


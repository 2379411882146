import { ChangeEvent, FC } from "react";

import "./profile.css";
import { User } from "../../context/types";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";

type UserProfileProps = {
  profile: User;
  handleUpdate: () => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  t: any;
};

const UserProfile: FC<UserProfileProps> = ({
  profile,
  handleUpdate,
  handleInputChange,
  loading,
  t
}) => {
  console.log({ profile });

  const triggerFileInput = () => {
    document.getElementById("fileInput")?.click();
  };

  return (
    <div>
      <div className="surface-0 border p-5 m-auto col-lg-6 col-md-9 col-sm-12 col-xs-12">
        <div className="Header mb-5">
          <h3 className="fw-bold"> {t("user_profile:account_header")} </h3>
        </div>
        <div className="profile mt-2 mb-5">
          <div className="user-profile bg-light">
            {!profile?.photo ? (
              <i
                className="pi pi-user text-cyan-500"
                style={{ fontSize: "5rem" }}
              ></i>
            ) : (
              <img className="photo" src={profile?.photo} alt="photo" />
            )}
            
          </div>
          <div className="pic-upload w-25">
            <input
              type="file"
              name="photo"
              id="fileInput"
              className="form-control"
              style={{ display: "none" }} // Hide the input
              onChange={handleInputChange}
            />
            <button 
              className="p-btn-rounded"
              onClick={triggerFileInput}
            >
              <i className="pi pi-camera"></i>
            </button>
          </div>
        </div>
        <div className="">
          <h4 className="mt-2 mb-4"> {t("user_profile:personal_info")} </h4>
          <hr />
          <form>
            <div className="row">
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="text"
                  name="first_name"
                  className="form-control p-3"
                  placeholder="First name"
                  value={profile?.first_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="text"
                  name="last_name"
                  className="form-control p-3"
                  placeholder="Last name"
                  value={profile?.last_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="date"
                  name="dob"
                  className="form-control p-3"
                  placeholder="date of birth"
                  value={profile?.dob}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <h5 className="ms-2"> Gender </h5>

                <div className="flex ms-2">
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      value="male"
                      checked={profile?.gender === "male"}
                      id="genderMale"
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="genderMale">
                      Male
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      value="female"
                      checked={profile?.gender === "female"}
                      id="genderFemale"
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="genderFemale">
                      Female
                    </label>
                  </div>
                </div>

                {/* <input
                  className="form-control p-3"
                  placeholder="gender"
                  value={gender}
                  onChange={(ev) => setGender(ev.target.value)}
                /> */}
              </div>
            </div>
          </form>
        </div>

        <div className="mt-5">
          <h4 className=" mt-4 mb-4"> {t("user_profile:contact_info")} </h4>
          <hr />
          <form>
            <div className="row">
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="email"
                  name="email"
                  className="form-control p-3"
                  placeholder="email"
                  value={profile?.email}
                  disabled
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  name="phone_number"
                  className="form-control p-3"
                  placeholder="phone number"
                  value={profile?.phone_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>

        {/* <div className="mt-5">
          <h4 className=" mt-4 mb-4">Additional Information</h4>
          <hr />
          <form>
            <div className="row">
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="text"
                  style={{ border: "none", backgroundColor: "#EEEEEE" }}
                  className="form-control p-3"
                  placeholder="village"
                  value={user?.village}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="text"
                  style={{ border: "none", backgroundColor: "#EEEEEE" }}
                  className="form-control p-3"
                  placeholder="village head"
                  value={user?.village_head}
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 col-xs-12 mt-3">
                <input
                  type="text"
                  style={{ border: "none", backgroundColor: "#EEEEEE" }}
                  className="form-control p-3"
                  placeholder="other village"
                  value={user?.other_village}
                />
              </div>
            </div>
          </form>
        </div> */}

        <div className="mt-5">
          <button
            className="btn btn-info w-25"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? "Saving..." : t("common:save_label")}
          </button>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default withTranslation(["user_profile", "common"])(UserProfile);

import { useEffect, useState } from 'react'
import { get } from '../../apiClient'
import SideBarComponent from "../../components/SharedComponents/NavComponents/SideBarComponent"
import TopNavBarComponent from '../../components/SharedComponents/NavComponents/TopNavBarComponent'
import { useTranslation, withTranslation } from 'react-i18next';
import MemberProfile from '../../components/MembersComponents/MemberProfile'
import { useParams, Link } from 'react-router-dom';

const ReadMemberAppModule = ({t}: any) => {

    const { id } = useParams();

    const [currentMember, setCurrentMember]: any = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=> {

        const fetchMember = async (id: any) => {
            // Add Loader Here
            const response = await get(`members/${id}`, headers)
            const data = await response
            const member = {
                data: data?.data,
                is_edit: true
            }

            setLoading(false);
        };
        // receive id from URL
        fetchMember(id);
    }, [])

    const headers: any = {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    // const [t]: any = useTranslation("members_module");

    // const nav = t("nav_options", { returnObjects: true });

    


    return (

        //this route should recieve an id of a member

        <div className='module-wrapper'>
            <div>
                {/* <SideBarComponent navData={nav} /> */}
            </div>
            <div className="module-container">
                <TopNavBarComponent />
                <div className="card">
                <Link className='back-link ' to={'/modules/members/'}>{t("common:back_button")}</Link>
                    { !loading ? <MemberProfile data={currentMember} /> : '' }
                </div>
            </div>
        </div>
    );
};

export default withTranslation(["eRegistration", "common"])(ReadMemberAppModule);

import EditorComponent from "../../../components/AdvisoryServicesComponents/EditorComponent";
import { useState, useEffect } from "react";
import { get } from "../../../apiClient";
import { useParams } from "react-router-dom";

interface Article {
  id: number;
  content: string;
  category: Category;
}

interface Category {
  id: number;
  image?: any;
  name: string;
}

const headers: any = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const Read = (): any => {
  const { article_id, cat_id } = useParams();
  const [article, setCategory] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchArticleById();
  }, [article]);

  async function fetchArticleById(): Promise<void> {
    setIsLoading(true);
    const response = await get(`articles/${article_id}`, headers);
    const { content }: Article = response.data;
    setCategory(content);
    setIsLoading(false);
  }

  return (
    <div className="module-wrapper">
      <div className="card">
        <h4>Create Content</h4>
        {!isLoading ? <EditorComponent propData={article} /> : ""}
      </div>
    </div>
  );
};

export default Read;

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { get, put } from "../../apiClient";
import ActionButtonsComponents from "../../components/AdvisoryServicesComponents/ActionButtonsComponents";
import { Dialog } from "primereact/dialog";
import { Form } from "react-bootstrap";
import "primeicons/primeicons.css";
import { Crumb, Category } from "./types";
import { BreadCrumb } from "primereact/breadcrumb";
import CategoryCard from "./CategoryCard";
import { Skeleton } from "primereact/skeleton";
import { withTranslation } from "react-i18next";

interface AdvisoryMainAppModuleProps {
  t: any;
}

const AdvisoryMainAppModule = ({t}: AdvisoryMainAppModuleProps) => {
  const { id } = useParams();
  const headers: any = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const defaultCategory: Category = {};
  const [categories, setCategories] = useState<Category[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<Crumb[]>([
    {
      label: "Advisories",
      url: "/modules/advisories",
    },
  ]);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [categoryName, setCategoryName] = useState<string>("");
  const [category, setCategory] = useState<Category>(defaultCategory);
  const [actionButtonsVisible, setActionButtonsVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleCheckboxChange = (data: any): void => {
    setActionButtonsVisible(true);
    setCategoryName(data.name);
    setCategory(data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async (): Promise<void> => {
    setIsLoading(true)
    const isParent: boolean = id === undefined;
    const Id = isParent ? "" : id;
    const response: any = await get(`categories/${Id}`, headers);
    const data: any = await response;
    const list: Array<Category> = isParent ? data?.data : data.data?.children;
    setCategories(list);
    setIsLoading(false)
  };

  const handleCategoryUpdate = async (): Promise<void> => {
    const payload: Category = {
      id: category.id,
      name: categoryName,
      image: selectedImage,
    };
    await put("categories", headers, payload.id, payload);
    fetchCategories();
    setActionButtonsVisible(false);
  };

  const handleCategoryDelete = () => {
  };

  const handleImageChange = (event: any): void => {
    const file = event.target.files[0];
    if (file) {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
    }
  };

  const handleNameChange = (event: any): void => {
    setCategoryName(event.target.value);
  };

  const Links = categories?.map((data: any): any => {
      return <CategoryCard key={id} data={data} handleCheckboxChange={(data) => handleCheckboxChange(data)} />;
  });

  return (
    <div className="p-3 silver-line">
      <BreadCrumb model={breadcrumbs} home={{ icon: "pi pi-home" }}  />
      <div className="mt-4" >
        <div className="">
          <div className="row">
            {isLoading ? 
              (
                <div>
                  <Skeleton height="15rem" className="mb-2" borderRadius="16px"></Skeleton>
                </div>
              ): Links
            }
          </div>
        </div>
      </div>
      <Dialog
        header={category.name}
        visible={actionButtonsVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setActionButtonsVisible(false);
        }}
      >
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <Form className="login-form">
                <Form.Group>
                  <Form.Label> {t("advisories:category_name")} </Form.Label>
                  <Form.Control
                    type="text"
                    name="category_name"
                    value={categoryName}
                    className="input"
                    onChange={handleNameChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label> {t("advisories:category_image")} </Form.Label>
                  <div className="row">
                    <div className="col-md-10">
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="category_image"
                        className="input"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="col-md-2">
                      {selectedImage && (
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{
                            width: "100%",
                            height: "50px",
                            marginTop: "0px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
        <ActionButtonsComponents
          update={handleCategoryUpdate}
          remove={handleCategoryDelete}
        />
      </Dialog>
    </div>
  );
};

export default withTranslation(["advisories"])(AdvisoryMainAppModule);

import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createHousehold,
  createHouseholdMember,
  deleteHousehouldMember,
  getHouseholdMembers,
  updateHouseholdMember,
} from "../endpoints";
import {
  HouseholdMember,
  Household as HouseholdType,
  Member,
} from "../../types";
import Household from "./Household";
import { Toast } from "primereact/toast";

type HouseholdContainerProps = {
  member: Member;
};

const HouseholdContainer: FC<HouseholdContainerProps> = ({ member }) => {
  const params = useParams();
  const toast = useRef(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [household, setHousehold] = useState<HouseholdType>({
    name: member.household?.name || "",
    id: member.household?.id,
  });
  const [memebrs, setHouseholdMembers] = useState<HouseholdMember[]>([]);

  const handleCreateHousehold = () => {
    if (!household) {
      return;
    }
    setLoading(true);

    createHousehold({
      head_id: member.id,
      name: household.name,
      serial_number: household.serial_number,
    })
      .then((response) => {
        setHousehold(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDeleteMember = (member: HouseholdMember) => {
    if (!household) {
      return;
    }
    setLoading(true);

    deleteHousehouldMember(member.id)
      .then(() => {
        fetchHouseholdMembers();
        setLoading(false);
        if (toast.current) {
          (toast.current as Toast).show({
            severity: "success",
            detail: `${member.name} has been successyfully deleted`,
            life: 2000,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleUpdateMember = (id: string | number, params: any) => {
    if (!household) {
      return;
    }
    setLoading(true);

    updateHouseholdMember(id, params)
      .then(() => {
        fetchHouseholdMembers();
        setLoading(false);

        if (toast.current) {
          (toast.current as Toast).show({
            severity: "success",
            detail: `Update complete been successyfully deleted`,
            life: 2000,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleCreateHouseholdMember = (params: any) => {
    if (!household) {
      return;
    }
    setLoading(true);

    createHouseholdMember(household.id, params)
      .then(() => {
        fetchHouseholdMembers();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchHouseholdMembers = async () => {
    if (!household.id) {
      return;
    }
    setLoading(true);
    getHouseholdMembers(household.id)
      .then((res) => {
        setLoading(false);
        setHouseholdMembers(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHouseholdMembers();
  }, []);

  return (
    <div>
      <Toast ref={toast} />

      <Household
        member={member}
        household={household}
        members={memebrs}
        handleCreateHousehold={handleCreateHousehold}
        setHousehold={setHousehold}
        handleCreateHouseholdMember={handleCreateHouseholdMember}
        loading={loading}
        handleDeleteMember={handleDeleteMember}
        handleUpdateMember={handleUpdateMember}
      />
    </div>
  );
};

export default HouseholdContainer;

import axios from "axios";
import verifyAuth from "./verifyAuth";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const DEFAULT_HEADERS: any = {
  "Content-Type": "application/json",
};

interface Data {
  url: string;
  payload: any | {};
  headers: any;
}

const UNAUTHORIZED_RESPONSE = {
  message: "Unauthorized",
  code: 401,
  response: {},
};

const NON_AUTHENTICATED_ROUTES = [
  "districts",
  "auth/token",
  "auth/verify",
  "auth/register/users",
  "tas",
  "epas",
  "villages",
  "sections",
];

// TO DO: HANDLE, 401, 403 to force a user logout automatically

export const post: any = async (data: Data): Promise<any> => {
  const isSkipVerification = NON_AUTHENTICATED_ROUTES.includes(data.url)
    ? true
    : await verifyAuth.verifyAuth();
  const isVerified: any = verifyAuth.isAuthenticated;

  if (isVerified || isSkipVerification) {
    try {
      const headers: any = data.headers;

      const response = await api.post(data.url, data.payload, {
        headers: { ...DEFAULT_HEADERS, ...headers },
      });

      return SuccessResponseBody(response);
    } catch (error: any) {
      return ErrorResponseBody(error);
    }
  } else {
    localStorage.setItem("token", "");
    return ErrorResponseBody(UNAUTHORIZED_RESPONSE);
  }
};

export const get: any = async (
  url: string,
  headers: any,
  id: any = ""
): Promise<any> => {
  await verifyAuth.verifyAuth();
  const isVerified: any = verifyAuth.isAuthenticated;

  if (isVerified || NON_AUTHENTICATED_ROUTES.includes(url)) {
    try {
      const response = await api.get(`${url}/${id}`, {
        headers: { ...DEFAULT_HEADERS, ...headers },
      });

      return SuccessResponseBody(response);
    } catch (error: any) {
      return ErrorResponseBody(error);
    }
  } else {
    localStorage.setItem("token", "");
    return ErrorResponseBody(UNAUTHORIZED_RESPONSE);
  }
};

export const put = async (
  url: string,
  headers: any,
  id: any,
  data: any
): Promise<any> => {
  await verifyAuth.verifyAuth();
  const isVerified: any = verifyAuth.isAuthenticated;

  if (isVerified || NON_AUTHENTICATED_ROUTES.includes(url)) {
    try {
      const response = await api.put(`/${url}/${id}`, data, {
        headers: { ...DEFAULT_HEADERS, ...headers },
      });

      return SuccessResponseBody(response);
    } catch (error: any) {
      return ErrorResponseBody(error);
    }
  } else {
    localStorage.setItem("token", "");
    return ErrorResponseBody(UNAUTHORIZED_RESPONSE);
  }
};

export const remove = async (url: string, id: number, headers: any): Promise<any> => {
  await verifyAuth.verifyAuth();
  const isVerified: any = verifyAuth.isAuthenticated;

  if (isVerified || NON_AUTHENTICATED_ROUTES.includes(url)) {
    try {
      const response = await api.delete(`/${url}/${id}`, {
        headers: { ...DEFAULT_HEADERS, ...headers }
      });

      return SuccessResponseBody(response);
    } catch (error: any) {
      return ErrorResponseBody(error);
    }
  } else {
    localStorage.setItem("token", "");
    return ErrorResponseBody(UNAUTHORIZED_RESPONSE);
  }
};

function SuccessResponseBody(content: any): any {
  const { status, data, statusText }: any = content;

  return {
    status,
    statusText,
    data,
  };
}

function ErrorResponseBody(content: any): any {
  const { message, code, response }: any = content;

  return {
    message,
    code,
    response,
    content,
  };
}

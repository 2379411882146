import React from "react";
import styles from "./PageLoader.module.css";
import { ProgressSpinner } from "primereact/progressspinner";
type PageLoaderProps = {
  text?: string;
};

const PageLoader = ({ text }: PageLoaderProps) => {
  return (
    <div className={styles.container}>
      <ProgressSpinner />
    </div>
  );
};

export default PageLoader;

import { Form } from "react-bootstrap";
import {
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { RegistrationContext } from "../../MembersComponents/MembersRegisterComponent";
import { get } from "../../../apiClient";
import { Checkbox } from "primereact/checkbox";
import { ProgressBar } from "primereact/progressbar";
import { Area } from "../../../types";
import { Badge } from 'primereact/badge';
import MapProvider from "../GeoLocationComponents/MapProvider";
import { MapConfig } from "../GeoLocationComponents/MapConfig"

interface LocationArea {
  district: Area;
  ta: Area;
  village: Area;
  fullname: string;
}

enum MapMarkStatus {
  Marked = 'success',
  Pending = 'warning',
  Unmarked = 'danger'
}

const openStreetMapConfig: MapConfig = {
  service: 'OpenStreetMap',
  center: [-13.2543, 34.3015],
  zoom: 13,
  tileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  locationSearchUrl: 'https://nominatim.openstreetmap.org/search?format=json&q=',
  locationNameSearchUrl: 'https://nominatim.openstreetmap.org/reverse?format=json&lat=',
  attribution: '&copy; OpenStreetMap contributors',
};

const googleMapsConfig: MapConfig = {
  service: 'GoogleMaps',
  center: [-13.2543, 34.3015],
  zoom: 13,
  apiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
  tileLayerUrl: 'https://maps.googleapis.com/maps/vt?x={x}&y={y}&z={z}&key=',
  locationSearchUrl: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  locationNameSearchUrl: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  attribution: '© Google',
};
import { withTranslation } from "react-i18next";

const AddressComponent = forwardRef(({ onChange, t }: any, ref): any => {
  const [districts, setDistricts] = useState<Area[]>([]);
  const [district, setDistrict] = useState<Area[]>([]);
  const [tas, setTas] = useState<Area[]>([]);
  const [epas, setEpas] = useState<Area[]>([]);
  const [epa, setEpa] = useState<Area[]>([]);
  const [sections, setSections] = useState<Area[]>([]);
  const [section, setSection] = useState<Area[]>([]);
  const [cooperatives, setCooperatives] = useState<Area[]>([]);
  const [cooperative, setCooperative] = useState<Area[]>([]);
  const [ta, setTa] = useState<Area[]>([]);
  const [groupVillageHead, setGroupVillageHead] = useState<string>("");
  const [villages, setVillages] = useState<Area[]>([]);
  const [village, setVillage] = useState<Area[]>([]);
  const [locationAreas, setLocationAreas] = useState<LocationArea>();
  const [isVillageOther, setIsVillageOther] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMapPicker, setShowMapPicker] = useState<boolean>(false);
  const [markValue, setMarkValue] = useState<MapMarkStatus>(MapMarkStatus.Unmarked);
  const [markedKey, setMarkedKey] = useState<string>('Pending');
  const [errors, setErrors] = useState<any>({});
  const data: any = useContext(RegistrationContext);
  const validateFrom = () => {
    const newErrors: any = {};
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useImperativeHandle(ref, () => ({
    validateFrom,
  }));
  useEffect(() => {
    // set location data from parent
    const fetchDistricts = async () => {
      setIsLoading(true);
      const filteredDistricts: any = await fetchAllDistricts();
      setDistricts(filteredDistricts);
      setIsLoading(false);
    };
    fetchDistricts();
    setContextData();

    // TO DO:
    // fetch coordinates status from the database, if the market against a particular village name
    setMarkValue(MapMarkStatus.Unmarked);
    const markedKeyValue: any = getEnumKeyByValue(MapMarkStatus, MapMarkStatus.Unmarked);
    setMarkedKey(markedKeyValue);


  }, []);
  const setContextData = (): void => {
    if (data?.district !== undefined) setDistrict([data?.district]);
    if (data?.traditional_authority !== undefined) setTa([data?.traditional_authority]);
    if (data?.epa !== undefined) setEpa([data?.epa]);
    if (data?.section !== undefined) setSection([data?.section]);
    if (data?.gvh !== undefined) setGroupVillageHead(data?.groupVillageHead);
    if (data?.village !== undefined) setVillage([data?.village]);
    if (data?.cooperative !== undefined) setCooperative([data?.cooperative]);
  }
  const handleDistrictInputChange = async (data: any): Promise<void> => {
    setIsLoading(true);
    setTa([]);
    setEpa([]);
    setSection([]);
    setCooperative([]);
    setVillage([]);
    setDistrict(data);
    onChange("district", data[0]);
    const tas: Area[] = await fetchTas(data[0]?.id);
    setTas(tas);
    setIsLoading(false);
  }
  const handleTaInputChange = async (data: any): Promise<void> => {
    setIsLoading(true);
    setEpa([]);
    setSection([]);
    setCooperative([]);
    setVillage([])
    setTa(data)
    onChange("traditional_authority", data[0]);
    const villages: Area[] = await fetchVillages(data[0]?.id);
    setVillages(villages);
    const epas: Area[] = await fetchEpas(data[0]?.id);
    setEpas(epas);
    setIsLoading(false);
  }
  const handleEpaInputChange = async (data: any): Promise<void> => {
    setIsLoading(true);
    setCooperative([]);
    setSection([]);
    setEpa(data);
    onChange("epa", data[0])
    const sections: Area[] = await fetchSections(data[0]?.id);
    setSections(sections);
    const cooperatives: Area[] = await fetchCooperatives(data[0]?.id);
    setCooperatives(cooperatives);
    setIsLoading(false);
  }
  const handleSectionInputChange = async (data: any): Promise<void> => {
    setSection(data);
    onChange("section", data[0]);
  };
  const handleCooperativeInputChange = async (data: any): Promise<void> => {
    setCooperative(data);
    onChange("cooperative", data[0]);
  };
  const handleVillageInputChange = async (data: any): Promise<void> => {
    setVillage(data);
    onChange("village", data[0]);
  };
  const handleOtherVillageInputChange = async (data: any): Promise<void> => {
    setGroupVillageHead(data?.target?.value);
    onChange("groupVillageHead", data?.target?.value);
  };
  const isVillageOtherCheck = (isOther: any): void => {
    setIsVillageOther(isOther);
  };
  const fetchAllDistricts = async (): Promise<Area[]> => {
    const response: any = await get("districts");
    return response.data.map((district: any): Area[] => {
      return district;
    });
  }
  const fetchTas = async (districtId: number): Promise<Area[]> => {
    const response: any = await get("districts", {}, `${districtId}/tas`);
    return response.data.map((item: any): Area[] => {
      return item;
    });
  };
  const fetchEpas = async (taId: number): Promise<Area[]> => {
    const response: any = await get("tas", {}, `${taId}/epas`);
    return response.data.map((item: any): Area[] => {
      return item;
    });
  };
  const fetchSections = async (epaId: number): Promise<Area[]> => {
    const response: any = await get("epas", {}, `${epaId}/sections`);
    return response.data.map((item: any): Area[] => {
      return item;
    });
  }
  const fetchCooperatives = async (epaId: number): Promise<Area[]> => {
    const response: any = await get("epas", {}, `${epaId}/cooperatives`);
    return response.data.map((item: any): Area[] => {
      return item;
    });
  };
  const fetchVillages = async (taId: number): Promise<Area[]> => {
    const response: any = await get("tas", {}, `${taId}/villages`);
    return response.data.map((item: any): Area[] => {
      return item;
    });
  };
  const getEnumKeyByValue = (enumObj: any, value: any[keyof any]): string | undefined => {
    return Object.keys(enumObj).find(key => enumObj[key as keyof any] === value);
  };

  const promptMapMarker = () : void => {
    const name: string = `${village[0].name}, ${ta[0].name}, ${district[0].name}, Malawi`;
    const location: LocationArea = {
      district: district[0],
      ta: ta[0],
      village: village[0],
      fullname: name
    }
    setLocationAreas(location);
    setShowMapPicker(true);
  }

  const invokeCancelMapMarker = (isClose: boolean): void => {
    setShowMapPicker(isClose);
  }

  const addressForm = (): any => {
    return (
      <Form.Group controlId="formStep1">
        <Form.Label>{t("common:select")} {t("common:district")} *</Form.Label>
        <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          options={districts}
          placeholder={`${t("common:select")} ${t("common:district")}`}
          selected={district}
          className="input pullErrorMessage"
          onChange={handleDistrictInputChange}
        />
        <Form.Label>{t("common:select")} {t("common:traditional_authority")} *</Form.Label>
        <Typeahead
          clearButton
          id="basic-typeahead-single"
          labelKey="name"
          options={tas}
          placeholder={`${t("common:select")} ${t("common:traditional_authority")}`}
          selected={ta}
          className="input pullErrorMessage"
          onChange={handleTaInputChange}
        />
        <Form.Label>{t("common:select")} {t("common:epa")} *</Form.Label>
        <Typeahead
          clearButton
          id="basic-typeahead-single"
          labelKey="name"
          options={epas}
          placeholder={`${t("common:select")} ${t("common:epa")}`}
          selected={epa}
          className="input pullErrorMessage"
          onChange={handleEpaInputChange}
        />
        <Form.Label>{t("common:select")} {t("common:cooperative")} *</Form.Label>
        <Typeahead
          clearButton
          id="basic-typeahead-single"
          labelKey="name"
          options={cooperatives}
          placeholder={`${t("common:select")} ${t("common:cooperative")}`}
          selected={cooperative}
          className="input pullErrorMessage"
          onChange={handleCooperativeInputChange}
        />
        <Form.Label>{t("common:select")} {t("common:section")} *</Form.Label>
        <Typeahead
          clearButton
          id="basic-typeahead-single"
          labelKey="name"
          options={sections}
          placeholder={`${t("common:select")} ${t("common:section")}`}
          selected={section}
          className="input pullErrorMessage"
          onChange={handleSectionInputChange}
        />
        <Form.Label>{t("common:group_village_head_label")}(GVH) *</Form.Label>
        <Form.Control
          type="text"
          name="otherVillage"
          className="input pullErrorMessage"
          value={groupVillageHead}
          onChange={handleOtherVillageInputChange}
          placeholder={`${t("common:village_name")}`}
        />
        <Form.Label>{t("common:select")} {t("common:village_label")} *</Form.Label>
        {!isVillageOther ? (
          <Typeahead
            clearButton
            id="basic-typeahead-single"
            labelKey="name"
            onChange={handleVillageInputChange}
            options={villages}
            placeholder={`${t("common:select")} ${t("common:village_label")}`}
            selected={village}
            className="input pullErrorMessage"
          />
        ) : (
          <Form.Control
            type="text"
            name="otherVillage"
            className="input pullErrorMessage"
            value={data.otherVillage}
            onChange={handleOtherVillageInputChange}
            placeholder={`${t("common:village_name")}`}
          />
        )}
        <div className="checkPusher"></div>

        <span>{t("eRegistration:cant_find_village")} </span>
        <Checkbox
          onChange={(e) => isVillageOtherCheck(e.checked)}
          checked={isVillageOther}
        ></Checkbox>{" "}
        <span></span>
        <p className="errorMessage">
          {" "}
          {errors.village && <span>{errors.village}</span>}{" "}
        </p>
        <hr />
        <div>
          <span className="pi pi-info-circle styled-link" style={{ marginRight: '0.5%', cursor: "pointer" }} onClick={() => { promptMapMarker(); }} />
          <label className="styled-link" style={{ marginRight: '0.5%', cursor: "pointer" }} onClick={() => { promptMapMarker(); }}>Mark address above with Google Maps</label>
          <img
            alt="profile"
            width="2.5%"
            height="2.5%"
            src={process.env.PUBLIC_URL + `/icons/map_marker.png`}
            style={{ cursor: "pointer", borderRadius: "2%" }}
            onClick={() => { promptMapMarker(); }}
          />
        </div>
        <div>
          <label className="styled-link" style={{ marginRight: '0.5%' }} >
            <Badge
              className={markValue == 'success'
                ? 'pi pi-check-circle'
                : markValue == 'warning'
                  ? 'pi pi-exclamation-triangle'
                  : 'pi pi-times-circle'}
              style={{ fontSize: '10px', padding: '5px', fontWeight: 'bold' }}
              value={` ${markedKey}`}
              severity={markValue}>
            </Badge>
          </label>
        </div>
        <hr />
      </Form.Group>
    )
  }
  return (
    <>
      {isLoading && !showMapPicker && (
        <div className="loader-margin">
          <ProgressBar
            mode="indeterminate"
            style={{ height: "2px" }}
          ></ProgressBar>
        </div>
      )}
      {!isLoading && !showMapPicker && (addressForm())}
      {<p>{showMapPicker}</p>}
      {!isLoading && showMapPicker && (<MapProvider config={openStreetMapConfig} mark_value={markValue} location_area={locationAreas} invokeCancelMapMarker={invokeCancelMapMarker} />)}
    </>
  );
});

export default withTranslation(["eRegistration", "common"])(AddressComponent);

import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMemberById } from "../endpoints";
import { Member } from "../../types";
import { Spinner } from "react-bootstrap";
import MemberDetails from "./MemberDetails";

type MemberDetailsContainerProps = {};

const MemberDetailsContainer: FC<MemberDetailsContainerProps> = ({}) => {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [member, setMember] = useState<Member>({} as Member);

  const id = params.id;

  const fetchMember = async () => {
    setLoading(true);
    getMemberById(id)
      .then((data) => {
        setLoading(false);

        setMember(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMember();
  }, []);

  return <MemberDetails member={member} isloading={loading} />;
};

export default MemberDetailsContainer;

// BarChart.tsx
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export interface BarChartProps {
  farmersData: number[];
  institutionsData: number[];
  labels: string[] | unknown[];
}

const BarChart: React.FC<BarChartProps> = ({
  farmersData,
  institutionsData,
  labels,
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy previous chart instance
      }
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        chartInstance.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels,
            datasets: [
              {
                label: "Farmers",
                data: farmersData,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                label: "Institutions",
                data: institutionsData,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy chart instance when component unmounts
      }
    };
  }, [farmersData, institutionsData, labels]);

  return <canvas ref={chartRef}></canvas>;
};

export default BarChart;

import { FC } from "react";
import { Member } from "../../types";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";

type DemographicDetailsProps = {
  member: Member;
  invokeEdit(member: Member, isEdit: boolean): void;
  t: any;
};

const DemographicDetails: FC<DemographicDetailsProps> = ({ member, invokeEdit, t }) => {
  function calculateAge(birthDate: any): any {
    const today = new Date();
    const birthDateObj = new Date(birthDate);

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  }

  return (
    <div>
      <div className="row align-items-center flex-row-reverse card">
        <div className="col-lg-6">
          <div className="about-text go-to">
            <h3 className="dark-color text-xl">{`${member?.first_name} ${member?.last_name}`}</h3>
            <h4 className="dark-color text-lg capitalize">{member?.gender}</h4>
            <hr />
            <h6 className="theme-color lead">{`${member?.epa?.name} EPA`}</h6>
            <hr />
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label> {t("common:date_of_birth")} </label>
                  <p>{member?.dob}</p>
                </div>
                <div className="media">
                  <label> {t("common:age_label")} </label>
                  <p>{calculateAge(member?.dob)} Years</p>
                </div>
                <div className="media">
                  <label> {t("common:district")} </label>
                  <p>{`${member?.district?.name}`}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media">
                  <label> {t("common:email_label")} </label>
                  <p>{`${member?.email}`}</p>
                </div>
                <div className="media">
                  <label> {t("common:phone_number")} </label>
                  <p>{`${member?.phone_number}`}</p>
                </div>
                <div className="media">
                  <label> {t("common:traditional_authority")}, {t("common:village_label")}</label>
                  <p>{`${member?.traditional_authority?.name}, ${member?.village?.name}`}</p>
                </div>
              </div>
              <hr />
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Button
                  icon="pi pi-user-edit"
                  label={`${t("common:edit_label")}`}
                  severity="info"
                  onClick={() => {invokeEdit(member, true)}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-avatar wrapper card">
            <img width="100%" src={member?.photo} title="" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"]) (DemographicDetails);

import "./HeaderComponent.css";
import { Menubar } from "primereact/menubar";
import MGLogo from "./malawi-gov.png";
import DaesLogo from "./daes-logo.png";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import SettingsComponent from "../../SettingsComponents/SettingsComponent";
import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
function MenubarDemo() {
  const [isSettingsOpen, setSettingsOpen]: any = useState(false);

  const submitSettingsRef: any = useRef();

  const saveAppSettings = (e: any): any => {
    // pass data to Child from here
    e.preventDefault();
    submitSettingsRef.current.submitChanges();
  };

  const hideDialog = (): any => {
    setSettingsOpen(false);
  };

  const productDialogFooter = (
    <React.Fragment>
      <div className="button-container">
        <Button
          label="Save Settings"
          icon="pi pi-times"
          onClick={saveAppSettings}
          className="settings-button"
        />
      </div>
      <div className="button-container">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={hideDialog}
          className="settings-button"
        />
      </div>
    </React.Fragment>
  );

  const start = (
    <Link to={"/"}>
      {" "}
      <img alt="logo" src={DaesLogo} height="60" className="mr-2"></img>{" "}
    </Link>
  );
  const end = (
    <Link to={"/"}>
      <img alt="logo" src={MGLogo} height="60" className="mr-2"></img>
    </Link>
  );

  return (
    <div>
      <div>
        <Menubar start={start} end={end} />
      </div>
      <Dialog
        visible={isSettingsOpen}
        style={{ width: "100rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="App Settings"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <SettingsComponent ref={submitSettingsRef} />
      </Dialog>
    </div>
  );
}

export default MenubarDemo;

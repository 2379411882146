import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface District {
  name: string;
  boundary: string;
}

interface PathProps {
  district: District;
  selectedDistricts: string[];
  //onClick: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void; remove onClick for now
  selectedColor: string;
  farmers: number[];
  institutions: number[];
  labels: string[] | unknown[];
}

const Path: React.FC<PathProps> = ({ selectedColor, district, selectedDistricts, farmers, institutions, labels }) => { //onClick, selectedDistricts }) => { remove onClick for now
  const { name, boundary } = district;

  const isHighlighted = (labels: string) => selectedDistricts.includes(labels);

  console.log(
    "Farmers: ", farmers,
    " Institution: ", institutions,
    " labels: ", labels
  )

  // Find the index of the current district's name in the labels array
  const districtIndex = labels.indexOf(name);
  const farmersCount = farmers[districtIndex] ?? 0; // Use 0 if no data available
  const institutionsCount = institutions[districtIndex] ?? 0; 

  return (<>
    <OverlayTrigger placement="top" overlay={
      <Tooltip id={`tooltip-${name}`}>
        <div>District: {name}</div>
        <div>Farmers: {farmersCount}</div>
        <div>Institutions: {institutionsCount}</div>
      </Tooltip>}>
      <path
        //data-for="svgTooltip"
        fill={isHighlighted(name) ? selectedColor : "inherit"}
        //onClick={onClick} removed onClick for now
        //data-tip={`District: ${name}`}
        d={boundary}
        xlinkTitle={name}
        id={name}
      />
    </OverlayTrigger>

  </>
  );
};

export default Path;

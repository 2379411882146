import { MODULE_TRANSLATION_KEYS } from "./constants";

export const getModuleTranslation = () => {};

type ModuleID = {
  key: string;
  transKey: string;
};
export const identifyModule = (moduleName: string): ModuleID => {
  const key = Object.keys(MODULE_TRANSLATION_KEYS).find((key) =>
    moduleName.includes(key)
  );
  if (!key)
    return {
      key: "",
      transKey: "side_nav",
    };
  return MODULE_TRANSLATION_KEYS[key as keyof typeof MODULE_TRANSLATION_KEYS];
};

import { post } from './apiClient';

const verifyAuth = {
    isAuthenticated: false,
    verifyAuth: async () => {

        const headers: any = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    
        const response = await post({ url: 'auth/verify', headers })
      
        if (response.status === 200) {
            verifyAuth.isAuthenticated = true;
        } else {
            verifyAuth.isAuthenticated = false;
        }

    }
};

export default verifyAuth
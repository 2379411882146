import React, { FC } from "react";
import TopNavBarComponent from "../components/SharedComponents/NavComponents/TopNavBarComponent";
import { Outlet, useLocation } from "react-router-dom";
import SidebarReactComponent from "../components/SharedComponents/NavComponents/SidebarReactComponent";
import { identifyModule } from "./util";
import Footer from "../components/SharedComponents/FooterComponents/Footer";

type RootLayoutProps = {
  children?: React.ReactNode;
};

const MainLayout: FC<RootLayoutProps> = ({}) => {
  const location = useLocation();
  const showNavBar = location.pathname.includes("modules");
  const moduleName = location.pathname;
  const module = identifyModule(moduleName);

  return (
    <main>
      <TopNavBarComponent />
      <div className="app">
        {showNavBar && (
          <div className="sidebar">
            <SidebarReactComponent />
          </div>
        )}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </main>
  );
};

export default MainLayout;

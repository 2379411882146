import { FC, useState, useEffect } from 'react';
import { Badge } from 'primereact/badge';
import { Area } from "../../../types";
import { Checkbox } from "primereact/checkbox";
interface MapLocation {
    latitude?: number | null;
    longitude?: number | null;
    accuracy?: number | null;
    name?: string | null;
    type?: string | null;
    mark_value?: MapMarkStatus | null;
    location_area?: LocationArea | null;
    locationFilters?: (data: string) => void;
}
interface LocationArea {
    district: Area;
    ta: Area;
    village: Area;
    fullname: string;
}

enum MapMarkStatus {
    Marked = 'success',
    Pending = 'warning',
    Unmarked = 'danger'
}

const MapReadingsComponent: FC<MapLocation> = ({ latitude, longitude, name, type, mark_value, location_area, locationFilters }: any): any => {
    const [markValue, setMarkValue] = useState<MapMarkStatus>(mark_value);
    const [markedKey, setMarkedKey] = useState<string>('Unmarked');
    const [locations, setLocations] = useState<string[]>([]);
    const [allLocations, setLocation] = useState<any>({});

    const onLocationChange = (e: any, location: any): void => {
        let _locations = [...locations];
        let _allLocations = { ...allLocations }
        if (e.checked) {
            _locations.push(e.value);
            _allLocations = { ...allLocations, ...location };
        } else {
            _locations.splice(_locations.indexOf(e.value), 1);
            const objectKey = Object.keys(location)
            delete allLocations[objectKey[0]];
            _allLocations = { ...allLocations }
        }
        setLocations(_locations);
        setLocation(_allLocations);
        let params = `${_allLocations?.village || ''} ${_allLocations?.ta || ''} ${_allLocations?.district || ''} 'Malawi'`
        locationFilters(params);
    }

    const checkboxForms = (locationArea: any): any => {
        return (locationArea ? <div className="custom-checkbox">
            <label htmlFor="">Name of place: </label>
            <label htmlFor="ingredient1" className="ml-2">{locationArea?.village?.name}</label>
            <Checkbox
                style={{ marginLeft: '0.5%', marginRight: '0.5%' }}
                value="Village"
                onChange={(e: any) => { onLocationChange(e, { village: locationArea?.village?.name }) }}
                checked={locations.includes('Village')} />
            <label className="ml-2">{locationArea?.ta?.name}</label>
            <Checkbox
                style={{ marginLeft: '1%', marginRight: '0.5%' }}
                value="TA" onChange={(e: any) => { onLocationChange(e, { ta: locationArea?.ta?.name }) }}
                checked={locations.includes('TA')} />
            <label className="ml-2">{locationArea?.district?.name}</label>
            <Checkbox
                style={{ marginLeft: '1%', marginRight: '0.5%' }}
                value="District" onChange={(e: any) => { onLocationChange(e, { ta: locationArea?.district?.name }) }}
                checked={locations.includes('District')} />
            <label className="ml-2">Malawi</label>
            <Checkbox style={{ marginLeft: '1%', marginRight: '0.5%' }}
                disabled
                value="Country"
                checked={true} />
        </div> : undefined)
    }

    useEffect(() => {
        setMarkValue(mark_value)
        const markedKeyValue: any = getEnumKeyByValue(MapMarkStatus, mark_value);
        setMarkedKey(markedKeyValue);
    }, []);

    const getEnumKeyByValue = (enumObj: any, value: any[keyof any]): string | undefined => {
        return Object.keys(enumObj).find(key => enumObj[key as keyof any] === value);
    };

    return (
        <>
            {type &&
                <label className="styled-link" >{type}
                    <Badge
                        className={markValue == 'success'
                            ? 'pi pi-check-circle'
                            : markValue == 'warning'
                                ? 'pi pi-exclamation-triangle'
                                : 'pi pi-times-circle'}
                        style={{ fontSize: '10px', padding: '5px', fontWeight: 'bold' }}
                        value={` ${markedKey}`}
                        severity={markValue}>
                    </Badge>
                </label>}
            <div style={{ marginTop: '1%', fontSize: '11px' }}>
                <p>Latitude: {latitude || '----------'}</p>
                <p>Longitude: {longitude || '----------'}</p>
                <p>{name && `Name of place: ${name}` || checkboxForms(location_area) || 'Name of place: ----------'}</p>
            </div>
        </>
    );
};

export default MapReadingsComponent;

import { useRef, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

const CameraComponent = ({ onChange, data }: any) => {
  const videoRef: any = useRef(null);
  const photoRef: any = useRef(null);
  const [hasPhoto, setHasPhoto]: any = useState(false);
  const [isCameraLaunched, setIsCameraLaunched]: any = useState(false);
  const [isCameraLoading, setIsCameraLoading] = useState<boolean>(false);

  let video: any = useRef(null);

  const isPlaying: any = useRef(false);
  const toast: any = useRef(null);

  function getVideo(): void {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 391, height: 300 } })
      .then((stream: any) => {
        video = videoRef.current;
        video.srcObject = stream;

        video.onplaying = function () {
          isPlaying.current = true;
        };

        video.onpause = function () {
          isPlaying.current = false;
        };
      })
      .catch((err: any) => console.log(err));
  }

  function playVid() {
    setIsCameraLaunched(true);
    return video?.play();
  }

  const width: number = 400;
  const height: number = 300;

  useEffect(() => {
    setIsCameraLoading(true)
    const img: any = new Image();
    img.src = data.photo;
    const photo = photoRef.current;
    photo.width = width;
    photo.height = height;
    const ctx = photo.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    if(data.photo) setHasPhoto(true);
    setIsCameraLoading(false);
  }, [data.photo]);

  function takePhoto() {
    const video = videoRef.current;
    const photo = photoRef.current;
    photo.width = width;
    photo.height = height;
    const ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    const image = photo.toDataURL("image/jpeg");

    const img = new Image();
    img.src = image;
    img.onload = () => {
      ctx.drawImage(video, 0, 0, width, height);
      onChange(image);
      setHasPhoto(true);
    };
    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Photo Captured!' });
  }

  function ClearPhoto() {
    const photo: any = photoRef.current;
    const ctx: any = photo.getContext("2d");
    ctx.clearRect(0, 0, photo.width, photo.height);
    setHasPhoto(false);
  }

  useEffect(() => {
    getVideo();
  }, []);

  return (
    <div className="wrapper">
      <Toast ref={toast} position="top-center" />
      <div className="row photo-container">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="row">
            <div className="col-ms-12 col-md-12 col-lg-12">
              <Button
                className="camera-button-lauch-container"
                icon="pi pi-camera"
                label="Launch Camera"
                severity="info"
                onClick={playVid}
                disabled={isCameraLaunched || isCameraLoading}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="row">
            <div className="col-ms-10 col-md-10 col-lg-10 captured-image-header">
              <h4>Captured Photo</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row photo-container">
        <div className="col-md-6 col-lg-6 col-sm-6">
          <div className="camera">
            <div className="card video-feed">
              <video ref={videoRef}></video>
            </div>
            <Button
              className="camera-button-container"
              label="Capture"
              severity="info"
              outlined
              disabled={!isCameraLaunched}
              onClick={takePhoto}
            />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6">
          <div className={"result" + (hasPhoto ? "hasPhoto" : "")}>
            <div className="card capture-photo-container">
              <canvas className="capture-photo" ref={photoRef}></canvas>
            </div>
            <Button
              className="camera-button-container-clear"
              label="Clear"
              severity="info"
              disabled={!hasPhoto}
              onClick={ClearPhoto}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraComponent;

import { createContext, useContext, useEffect, useState } from "react";
import { ForumPost } from "../../../types";
import { createPost, deletePost, getPosts } from "../../endpoints";
import { useNavigate } from "react-router-dom";

type ForumPostRequest = {
  content?: string;
  title?: string;
  category_id?: number;
};

interface PostContextProps {
  posts: ForumPost[];
  isLoading: boolean;
  offset?: number;
  limit?: number;
  count?: number;
  setPosts: (posts: ForumPost[]) => void;
  setOffset: (offset: number) => void;
  createPost: (post: ForumPostRequest) => void;
  deletePost: (id: string | number) => void;
  handlePageChange: (nextPage: number) => void;
}

interface PostProviderProps {
  children: React.ReactNode;
}

export const PostContext = createContext<PostContextProps>({
  posts: [],
  isLoading: true,
  offset: 0,
  limit: 10,
  count: 0,
  setPosts: () => {},
  setOffset: () => {},
  createPost: (post: ForumPostRequest) => {},
  deletePost: (id: string | number) => {},
  handlePageChange: (nextPage: number) => {},
});

export function PostProvider(props: PostProviderProps) {
  const [posts, setPosts] = useState<ForumPost[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  // useEffect(() => {
  //   setLoading(true);
  //   getPosts(offset)
  //     .then((response) => {
  //       console.log(response.offset);
  //       setLoading(false);
  //       setPosts(response.data);
  //       setOffset(Number(response.offset));
  //       setCount(response.count);
  //       setLimit(response.limit);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, [offset]);

  useEffect(() => {
    if (posts.length > 0) return;
    setLoading(true);
    getPosts()
      .then((response) => {
        setLoading(false);
        setPosts(response.data);
        setOffset(response.offset);
        setCount(response.count);
        setLimit(response.limit);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleCreatePost = async (post: ForumPostRequest) => {
    createPost({ ...post, category_id: 2 })
      .then((response) => {
        setPosts([response, ...posts]);
      })
      .catch(() => {});
  };

  const handleDeletePost = async (id: string | number) => {
    deletePost(id)
      .then((response) => {
        setPosts(posts.filter((post) => post.id !== id));
      })
      .catch(() => {});
  };

  const handlePageChange = async (nextPage: number) => {
    setLoading(true);
    getPosts(nextPage)
      .then((response) => {
        console.log(response.offset);
        setLoading(false);
        setPosts(response.data);
        setOffset(Number(response.offset));
        setCount(response.count);
        setLimit(response.limit);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <PostContext.Provider
      value={{
        posts,
        isLoading: loading,
        offset: offset,
        limit: limit,
        count: count,
        setPosts,
        setOffset,
        createPost: handleCreatePost,
        deletePost: handleDeletePost,
        handlePageChange: handlePageChange,
      }}
    >
      {props.children}
    </PostContext.Provider>
  );
}

export const useForum = () => {
  const postContext = useContext(PostContext);

  if (!postContext) {
    throw new Error("useForum must be used within an PostContextProvider");
  }

  return postContext;
};

import React, { Fragment, useState, useEffect } from "react";
import { Path, districtsData } from "../../components/MalawiMapComponents";
import { Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { api } from "../../apiClient";
import { DistrictData, DistrictReportData } from "../../types";
import {
  BarChartProps,
} from "../../components/ReportComponents/BarChart";

interface MalawiMapProps {
  onClick?: (selectedDistricts: string[]) => void;
  height?: number;
  selectedColor?: string;
  fill?: string;
  t: any;
}

const MalawiMap: React.FC<MalawiMapProps> = ({
  onClick = () => {},
  height = 500,
  selectedColor = "#0468b1",
  fill = "#9D9D9D",
  t,
}) => {
  const initialSelectedDistricts = ["Mzimba", "Balaka", "Kasungu"];
  const [selectedDistricts, setSelectedDistricts] = useState<string[]>(
    initialSelectedDistricts
  );

  const [reportData, setReportData] = useState<DistrictReportData>();
  const [barGraphData, setBarGraphData] = useState<BarChartProps>();

  // const updateSelectedDistricts = (
  //   e: React.MouseEvent<SVGPathElement, MouseEvent>
  // ) => {
  //   const district = e.currentTarget.id;

  //   // Prevent changes to the initial selected districts
  //   if (initialSelectedDistricts.includes(district)) {
  //     return;
  //   }

  //   const updatedSelectedDistricts = selectedDistricts.includes(district)
  //     ? selectedDistricts.filter((dist) => dist !== district)
  //     : [...selectedDistricts, district];

  //   setSelectedDistricts(updatedSelectedDistricts);
  //   onClick(updatedSelectedDistricts);
  // };

  useEffect(() => {
    const fetchStats = () => {
      api.get("/reports").then((response) => {
        setReportData(response.data);
        const data = response.data;
        const labels = [
          ...new Set(data.data.map((item: DistrictData) => item.district)),
        ];
        const barGraphData = {
          labels: labels,
          farmersData: data.data
            .filter((i: DistrictData) => i.type === "Farmer")
            .map((item: DistrictData) => item.count),
          institutionsData: data.data.map(
            (item: DistrictData) => item.organisationCount
          ),
        };
        setBarGraphData(barGraphData);
      });
    };
    fetchStats();
  }, []);

  const renderFilterMap = () => {
    return (
      <g id="Map-Filter" transform="translate(26.000000, 160.000000)">
        <g id="districts" transform="translate(42.000000, 71.000000)">
          {barGraphData && districtsData.map((district: any) => (
            <Path
              key={district.name}
              district={district}
              selectedDistricts={selectedDistricts}
              //onClick={updateSelectedDistricts} remove onClick in path component
              selectedColor={selectedColor}
              farmers={barGraphData?.farmersData || []}
              institutions={barGraphData?.institutionsData || []}
              labels={barGraphData?.labels || []}
            />
          ))}
        </g>
      </g>
    );
  };

  const renderLegend = () => {
    const selectedDistrictsCount = selectedDistricts.length;

    if (selectedDistrictsCount === 0) {
      return (
        <div className="legend">
          <h3>No data to show. Click on the Map!</h3>
        </div>
      );
    } else {
      const legendHeader = `${t('total_districts')} ${selectedDistrictsCount}`;

      return (
        <div className="legend">
          <h3>{legendHeader}</h3>
          <ul>
            {selectedDistricts.sort().map((district) => (
              <li key={district}>{district}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <Fragment>
      {renderLegend()}
      <svg
        id="svg"
        className="map"
        viewBox="0 0 195 481"
        version="1.1"
        style={{ height }}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Malawi Map"
            transform="translate(-68.000000, -231.000000)"
            fill={fill}
          >
            {renderFilterMap()}
          </g>
        </g>
      </svg>
      <Tooltip id="svgTooltip" />
    </Fragment>
  );
};

export default withTranslation(["reports"])(MalawiMap);
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import RegisterUserComponent from "./components/UsersComponents/RegisterUserComponent";
import LoginUserComponent from "./components/UsersComponents/LoginUserComponent";
import "primeflex/primeflex.css";
import ProtectedRoute from "./ProtectedRoute";
import MainModule from "./app_modules/index";

// Members Module
import MembersAppModule from "./app_modules/members";
import MembersReportMainModule from "./app_modules/members/reports/index";
import CreateMemberAppModule from "./app_modules/members/create";
import ReadMemberAppModule from "./app_modules/members/read";

// User Profile
import UserProfile from "./app_modules/user_profile/UserProfileContainer";

// Advisories Module
import AdvisoriesAppModule from "./app_modules/advisory_services";
import ReadAdvisoriesAppModule from "./app_modules/advisory_services/read";
import CreateAdvisoriesAppModule from "./app_modules/advisory_services/articles/create";
import ReadAdvisoriesArticlesAppModule from "./app_modules/advisory_services/articles/read";

// Messaging Module

// Settings Module
import SettingsAppModule from "./app_modules/settings";
import GeoSettingsAppModule from "./app_modules/settings/geo";
import ReportsAppModule from "./app_modules/reports";
import UserContainer from "./app_modules/settings/UserContainer";

import ReportLayout from "./app_modules/reports/layout";
import SettingsLayout from "./app_modules/settings/layout";
import MainLayout from "./layout/MainLayout";
import Forum from "./app_modules/messaging/ForumContainer";
import MemberDetailsContainer from "./app_modules/members/MemberDetailsContainer";
import { PostProvider } from "./app_modules/messaging/context";
import ViewForumContainer from "./app_modules/messaging/ViewForumContainer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" index element={<MainModule />} />
          <Route element={<ProtectedRoute redirectTo="/" />}>
            <Route path="/profile" element={<UserProfile />} />
          </Route>
          <Route path="modules" element={<ProtectedRoute redirectTo="/" />}>
            <Route path="members">
              <Route path="" element={<MembersAppModule />} />
              <Route path=":id" element={<MemberDetailsContainer />} />
              <Route path="read/:id" element={<ReadMemberAppModule />} />
              <Route path="read/:id" element={<ReadMemberAppModule />} />
              <Route path="create" element={<CreateMemberAppModule />} />
              <Route path="read/:id" element={<ReadMemberAppModule />} />
              <Route path="reports">
                <Route path="" element={<MembersReportMainModule />} />
              </Route>
            </Route>
            <Route path="advisories">
              <Route path="" element={<AdvisoriesAppModule />} />
              <Route path="read/:id" element={<ReadAdvisoriesAppModule />} />
              <Route
                path=":cat_id/articles/create"
                element={<CreateAdvisoriesAppModule />}
              />
              <Route
                path=":cat_id/articles/:article_id/read"
                element={<ReadAdvisoriesArticlesAppModule />}
              />
            </Route>
            <Route path="messaging">
              <Route
                path=""
                element={
                  <PostProvider>
                    <Forum />
                  </PostProvider>
                }
              />
              <Route path=":id" element={<ViewForumContainer />} />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route path="settings">
                <Route path="" element={<SettingsAppModule />} />
                <Route path="geo" element={<GeoSettingsAppModule />} />
                <Route path="users" element={<UserContainer />} />
              </Route>
            </Route>
            <Route element={<ReportLayout />}>
              <Route path="reports">
                <Route path="" element={<ReportsAppModule />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/login" element={<LoginUserComponent />} />
        <Route path="auth">
          <Route path="register" element={<RegisterUserComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { FC, MouseEventHandler } from "react";
import { useState } from "react";
import "./messaging.css";
import PostEditor from "./PostEditor";
import { useForum } from "./context";
import { InputText } from "primereact/inputtext";
import { withTranslation } from "react-i18next";

type PostProps = {
  t: any;
};

type PostRequest = {
  content?: string;
  title?: string;
};

const CreatePost: FC<PostProps> = ({t}) => {
  const [showModal, setShowModal] = useState(false);
  const [postParams, setPostParams] = useState<PostRequest>();

  const { createPost } = useForum();

  const handleOnChange = (value: string) => {
    setPostParams((prev) => ({
      ...prev,
      content: value,
    }));
  };

  const handleTitleChange = (value: string) => {
    setPostParams((prev) => ({
      ...prev,
      title: value,
    }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!postParams) return;

    createPost(postParams);
    closeModal();
  };

  return (
    <div>
      <button className="postBtn fs-5 btn btn-primary" onClick={openModal}>
        <span className="pi pi-pencil mr-2" />
        {t("forum:new_topic_button")}
      </button>

      {showModal && (
        <div className="model" onClick={closeModal}>
          <div className="model-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h3 className="text-lg"> {t("forum:create_new_topic")} </h3>

            <div className="flex flex-column gap-2">
              <InputText
                width={"100%"}
                placeholder={`${t("forum:topic_title")}`}
                onChange={(e) => handleTitleChange(e.target.value)}
              />

              <PostEditor
                className="rounded"
                onChange={handleOnChange}
                value={postParams?.content}
              />
            </div>
            <div className="flex">
              <button
                type="button"
                className="btn btn-info me-1"
                onClick={handleSubmit}
              >
                {t("forum:post_button")}
              </button>
              <button
                type="button"
                className="btn btn-danger ms-1"
                onClick={closeModal}
              >
                {t("common:close_button")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation(["forum", "common"])(CreatePost);

import { FC, useEffect, useState } from "react";
import Forum from "./Forum";
import { useForum } from "./context";

type ForumContainerProps = {};

const ForumContainer: FC<ForumContainerProps> = () => {
  const { posts } = useForum();

  if (!posts) return <div>Loading...</div>;

  return <Forum />;
};

export default ForumContainer;

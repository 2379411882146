import { Form, Button } from "react-bootstrap";
import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderCompomemt from "../SharedComponents/NavComponents/HeaderComponent";
import { post } from "../../apiClient";
import { Toast } from "primereact/toast";
import ERRORS from "../../ErrorMessages";
import { useAuth } from "../../context/AuthContext";
import ReCAPTCHA from "react-google-recaptcha";

const LoginUserComponent = () => {
  const recaptchaRef = useRef(null);
  const toast: any = useRef(null);
  const siteKey = process.env.REACT_APP_SITE_KEY || "";
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [recaptchaError, setRecaptchaError] = useState("");
  const [loginDetails, setLoginDetails] = useState({
    email: undefined,
    password: undefined,
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (event.target === undefined) return;
    setLoginDetails({ ...loginDetails, [name]: value });
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setRecaptchaError("");
    let recaptchaValue;
    if (
      recaptchaRef &&
      recaptchaRef.current &&
      (recaptchaRef.current as any).getValue
    ) {
      recaptchaValue = (recaptchaRef.current as any).getValue();
    }
    if (!recaptchaValue) {
      setRecaptchaError("Please Solve the reCAPTCHA");
      return;
    }
    localStorage.setItem("token", "");
    const { email, password }: any = loginDetails;
    const results = await post({
      url: "auth/token",
      payload: { username: email, password },
    });

    if (results?.code && ERRORS.includes(results?.code)) {
      toast.current.show({
        severity: "warn",
        summary: "Info",
        detail: `${results?.code}!`,
      });
    } else if (results?.response?.status === 401) {
      toast.current.show({
        severity: "error",
        summary: "Failed to login",
        detail: "Email/Password Invalid!",
      });
    } else if (results?.status === 200) {
      localStorage.setItem("user", JSON.stringify(results.data.user));
      localStorage.setItem("token", results.data.access_token);
      setUser(results.data.user);
      setIsLoggedIn(true);
      navigate("/");
    }
  };

  return (
    <>
      <style>{`
                .login-container {
                    max-width: 400px;
                    margin: 0 auto;
                    padding: 20px;
                    background-color: #f9f9f9;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }

                .login-form {
                    margin-top: 20px;
                }

                .input {
                    width: 100%;
                    margin-bottom: 15px;
                }

                .login-btn {
                    width: 100%;
                    margin-top: 15px;
                }

                .register-link {
                    margin-top: 10px;
                    text-align: center;
                }

                .register-link a {
                    color: #007bff;
                    text-decoration: none;
                }

                .register-link a:hover {
                    text-decoration: underline;
                }
            `}</style>

      <div>
        <Toast ref={toast} position="top-center" />
        <HeaderCompomemt />

        <br />

        <div
          className="flex align-items-center justify-content-center"
          style={{ height: "75vh" }}
        >
          <div className="surface-card p-4 shadow-2 border-round lg:w-6 sm:w-9 xs:w-12">
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">
                Welcome Back
              </div>
              <span className="text-600 font-medium line-height-3">
                Don't have an account?
              </span>
              <span className="font-medium no-underline ml-1 text-blue-500 cursor-pointer">
                <Link to="/auth/register" className="no-underline">
                  Sign up
                </Link>
              </span>
            </div>

            <div>
              <Form className="login-form" onSubmit={submit}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    className="input"
                    required={true}
                    aria-errormessage={"Password is required"}
                    value={loginDetails.email || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    className="input"
                    required={true}
                    aria-errormessage={"Password is required"}
                    value={loginDetails.password || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <div className="flex flex-column justify-content-center mt-3 m-auto" style={{width: '305px'}}>
                  <ReCAPTCHA sitekey={siteKey} ref={recaptchaRef} />
                
                  {recaptchaError && (
                    <p className="text-sm text-danger">{recaptchaError}</p>
                  )}
                </div>
                <Button
                  variant="info"
                  type="submit"
                  className="login-btn w-full"
                >
                  Log in
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUserComponent;

import { FC, useEffect, useState } from "react";
import HomePageStats from "./HomePageStats";
import { api } from "../apiClient";
import { DistrictReportData } from "../types";
import { ProgressBar } from "primereact/progressbar";
import { HOME_PAGE_STATS } from "../constants/homePageStats";

const StatisticsContainer: FC<{}> = () => {
  const [reportData, setReportData] = useState<DistrictReportData>();


  useEffect(() => {
    const fetchStats = () => {
      api.get("/reports").then((response) => {
        setReportData(response.data);
      });
    };
    fetchStats();
  }, []);

  if (!reportData) {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  }

  return <HomePageStats data={reportData} />;
};

export default StatisticsContainer;

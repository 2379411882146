

const MembersAppModule = () => {

    return (

        <div className='module-wrapper'>
            <div className="module-container">
                
                <div>
                    Reports
                </div>
            </div>
        </div>
    );
};

export default MembersAppModule;
export type Category = {
  id?: number;
  name?: string;
  image?: string;
};

export type Crumb = {
  label?: string;
  url?: string;
};


export enum ActionOption {
  Categories,
  Articles,
  CreateArticle,
  UpdateArticle
}

export type ArticlePayload = {
  id?: number,
  richContent: string,
  category_id?: number,
  tags?: Array<string>,
  title?: string
}

import {FC} from "react";
import "./messaging.css";
import { withTranslation } from "react-i18next";

type SearchProps = {t: any};

const Search: FC<SearchProps> = ({t}) => {
  return (
    <div className="search-bar">
      <i className="pi pi-search"></i>
      <input type="text" placeholder={`${t('common:search_label')}`} />
    </div>
  )
}

export default withTranslation(["common"])(Search);

import SettingsComponent from "../../components/SettingsComponents/SettingsComponent";
//import { I18nextProvider, useTranslation } from 'react-i18next';
import {BreadCrumb} from "primereact/breadcrumb";

const GeoSettingsAppModule = () => {

    //console.log(I18nextProvider)

    //const [t]: any = useTranslation("settings_module");

    //const nav = t("nav_options", { returnObjects: true });
    const items = [
        { label: 'Settings' },
        { label: 'Language', url: '/modules/settings' },
        { label: 'Locations', url: '/modules/settings/geo' }
    ];
    const home = { icon: 'pi pi-home', url: '/' }

    return (

        <div className='module-wrapper'>
            <div className="module-container">
            
                <BreadCrumb model={items} home={home}/>
                <div className="card">
                    <SettingsComponent/>
                </div>
            </div>
        </div>
    );
};

export default GeoSettingsAppModule;
import RegisterComponent from "../../components/MembersComponents/MembersRegisterComponent";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MembersRegisterAppModule = ({t}: any) => {
  const items = [
    { label: "Farmers" },
    { label: "Manage", url: "/modules/members" },
    { label: "Register", url: "/modules/members/create" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  return (
    <div className="p-5 silver-line">
      {/* <BreadCrumb model={items} home={home} /> */}
      <div className="border-round">
        <div className="text-3xl font-medium text-900 mb-3">
          <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
            <div className="text-2xl text-primary font-bold mb-3">
              {t("eRegistration:register_new_farmer")}
            </div>
          </div>
        </div>
        <div>
        <div>
            <Link to="/modules/members/">
              <Button
                label={t("common:back_button")}
                type="button"
                className="p-button-outlined"
              />
            </Link>
          </div>
          <RegisterComponent editData={{ is_edit: false }} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(MembersRegisterAppModule);

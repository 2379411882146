import { withTranslation } from "react-i18next";

const ArticlesComponent = ({
  content,
  currentPage,
  numberOfPages,
  notifyEdit,
  t
}: any) => {
  const dateCreated = (dateString: string): any => {
    const currentDateTime = new Date(dateString);
    return currentDateTime.toLocaleString();
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="flex justify-content-between">

          <h6 style={{ float: "right" }}>
            <span style={{ fontWeight: 'bolder' }}>
              {t("common:created_by")}: {content?.author?.first_name} {content?.author?.last_name}
            </span> <br />
            <span style={{ fontStyle: 'italic', paddingLeft: '.4rem' }}>
              {dateCreated(content?.created_at)}
            </span>
          </h6>
          <h6
            style={{ float: "right", marginRight: "1%", color: '#0d6efd', cursor: 'pointer', fontWeight: 'bolder' }}
            onClick={() => notifyEdit({ is_edit: true, content })}
          >
            <i className="pi pi-pencil me-2"></i>
            {t("common:edit_label")}
          </h6>
        </div>

        <div
          className="mt-5 py-5 pl-8"
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
            borderRadius: '.4rem'
          }}
        >
          <h4>{content.title}</h4>
          <div
            dangerouslySetInnerHTML={{ __html: content.content }}
            style={{
              maxWidth: "70%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["common","advisories"])(ArticlesComponent);
export const SideBarMenuModules = [
  {
    title: "e_registration",
    to: "modules/members",
    icon: "pi pi-user-plus",
  },
  {
    title: "advisories",
    to: "modules/advisories",
    icon: "pi pi-verified"
  },
  {
    title: "extention_workers",
    to: "modules/members",
    icon: "pi pi-user"
  },
  {
    title: "reports",
    to: "modules/reports",
    icon: "pi pi-chart-bar"
  },
  {
    title: "messaging",
    to: "modules/messaging",
    icon: "pi pi-comments"
  },
  {
    title: "settings",
    to: "modules/settings",
    icon: "pi pi-cog"
  },
]

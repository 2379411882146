import React, { useState, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
// import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import DropDown from './dropDown';
import { Language } from './types';
import 'primeicons/primeicons.css';
import { useAuth } from '../../../context/AuthContext';

interface AccountOption {
    id: number
    name: string
}
export default function TopNavBarComponent(): any {
    

    const [_, i18n]: any = useTranslation("nav");
    const toast: any = useRef(null);
    const { setUser, user} = useAuth()
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState('English');

    const languages: Array<Language> = [
        { 
            name: 'English', 
            code: 'en', 
            id: 1 
        },
        { 
            name: 'Tumbuka', 
            code: 'tum', 
            id: 2 
        },
        { 
            name: 'Chichewa', 
            code: 'ny', 
            id: 3 
        }
    ];
    const handleLanguageChange = (language: Language) => {
        i18n.changeLanguage(language?.code);
        setSelectedLanguage(language.name);
        toast.current.show({ severity: 'success', summary: 'Language Settings', detail: `Switched to ${language.name} mode!` });
      };
    
    const accountOptions: Array<AccountOption> = [
        {
            id: 1,
            name: 'My Account'
        },
        {
            id: 2,
            name: 'Logout'
        }
    ]
    const handleAccountOption = (option: AccountOption): void => {
        switch (option.id) {
            case 1:
                navigate('/profile');
                break;
            case 2:
                handleLogout()
                break;
            default:
                break;
        }
    };
    function handleLogout(): void {
        localStorage.setItem("token", "");
        setUser(null);
        navigate('/');
    }

    // reload page before navigating to user profile
    const userProfile = () => {
        location.reload();
    }

    const startContent = (
        <React.Fragment>
            <Link to="/"
                className="p-link inline-flex justify-content-center align-items-center text-white h-4rem w-4rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200">
                <i className="pi pi-home text-2xl fs-2"></i>
            </Link>
            <svg width="70" height="70" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            </svg>
        </React.Fragment>
    );
    const centerContent = (
        <div className="flex flex-wrap align-items-center gap-3">
            
            {/* <button
                className="p-link inline-flex justify-content-center align-items-center text-white h-3rem w-3rem border-circle hover:bg-white-alpha-10 transition-all transition-duration-200">
                <i className="pi pi-cog text-2xl"></i>
            </button> */}
            <div>
                <div className="p-toolbar-group-left">
                    {/* custom dropdown language switcher */}
                    <DropDown languages={languages} selectedLanguage={selectedLanguage} onSelect={handleLanguageChange} />
                </div>
            </div>

        </div>
    );

    const endContent = user ? (
        <React.Fragment>
            <div className="">
                <div className="p-toolbar-group-left">
                    {/* <Dropdown optionLabel="name" options={accountOptions} placeholder={`${user.first_name} ${user.last_name}`} onChange={(e) => handleAccountOption(e.value)} /> */}
                    <button className="mb-2 p-link me-3" onClick={userProfile} style={{border: 'none', backgroundColor: 'transparent'}}>
                        <Link to={'/profile'}> <i className="pi pi-user text-2xl text-light border-circle hover:bg-white-alpha-10 transition-all transition-duration-200 p-3"></i> </Link>
                    </button>
                    <button className="mb-2 p-link me-3" onClick={handleLogout} style={{border: 'none', backgroundColor: 'transparent'}}> <i className="pi pi-power-off text-2xl text-light border-circle hover:bg-white-alpha-10 transition-all transition-duration-200 p-3"></i> </button>
                </div>
            </div>
        </React.Fragment>
    ) : null;

    return (
        <div className="">
            <Toast ref={toast} position="top-center" />
            <Toolbar start={startContent} center={centerContent} end={endContent} className="m-2"
                style={{border:'none', borderRadius: '1rem', backgroundImage: 'linear-gradient(to right, var(--bluegray-500), var(--bluegray-800))'}} />
        </div>
    );
}

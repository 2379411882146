import ArticleComponent from "./ArticleComponent";
import PaginationComponent from "../SharedComponents/PaginationComponent";
const ArticlesComponent = ({ notes, articles, itemsPerPage, currentPage, onPageChange, notifyEdit }: any) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentNotes = articles?.slice(startIndex, endIndex);

  return (
    <div>
      
      {currentNotes.map((note: any, index : any) => (
        <ArticleComponent key={index} content={note} notifyEdit={notifyEdit} />
      ))}
      {/* <PaginationComponent currentPage={currentPage} totalPages={Math.ceil(notes.length / itemsPerPage)} onPageChange={onPageChange} /> */}
    </div>
  );
};

export default ArticlesComponent;

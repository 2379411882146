import { Form } from 'react-bootstrap';
import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { get } from '../../apiClient';
import { Toast } from 'primereact/toast';

const SettingsComponent = forwardRef(({ }: any, ref): any => {
    const [districts, setDistricts] = useState<any>([]);
    const [district, setDistrict] = useState<any>([]);

    const [tas, setTas] = useState<any>([]);
    const [ta, setTa] = useState<any>([]);
    const [isTaDisabled, setTaIsDisabled] = useState<boolean>(true);

    const [epas, setEpas] = useState<any>([]);
    const [epa, setEpa] = useState<any>([]);
    const [isEpaDisabled, setEpaIsDisabled] = useState<boolean>(true);

    const [sections, setSections] = useState<any>([]);
    const [section, setSection] = useState<any>([]);
    const [isSectionDisabled, setSectionIsDisabled] = useState<boolean>(true);

    const [cooperative, setCooperative] = useState<any>([]);
    const [isCooperativeDisabled, setCooperativeIsDisabled] = useState<boolean>(true);

    const [errors, setErrors]: any = useState({});

    const toast: any = useRef(null);

    useEffect(() => {
        const fetchDistricts = async () => {
            const response = await get('districts')
            const filteredDistricts = response?.data?.map((district: any) => {
                return district
            })
            setDistricts(filteredDistricts);
        }
        fetchDistricts();

        const settingsString: any = localStorage.getItem("app_settings")
        const settings = JSON.parse(settingsString);
        setDistrict(settings?.district);
        setTa(settings?.ta);
        setEpa(settings?.epa);
        setSection(settings?.section);
        setCooperative({ ...cooperative, cooperative: settings?.cooperative?.cooperative });
    }, []);


    const handleDistrictInputChange = async (data: any) => {
        const response = await get('districts', {}, `${data[0]?.id}/tas`)
        const filteredResponse = response?.data?.map((item: any) => {
            return item
        });

        setDistrict(data)
        setTas(filteredResponse);
        setTaIsDisabled(false);
        setEpaIsDisabled(true);
        setSectionIsDisabled(true);
        setCooperativeIsDisabled(true);
        setTa([]);
        setEpa([]);
        setSection([]);
        setCooperative("");
    };

    const handleTaInputChange = async (data: any) => {
        const response = await get('tas', {}, `${data[0]?.id}/epas`);
        const filteredResponse = response?.data?.map((item: any) => {
            return item
        });

        setTa(data);
        setEpas(filteredResponse);
        setEpaIsDisabled(false);
        setSectionIsDisabled(true);
        setCooperativeIsDisabled(true);
        setEpa([]);
        setSection([]);
        setCooperative("");
    };

    const handleEpaInputChange = async (data: any) => {
        const response = await get('epas', {}, `${data[0]?.id}/sections`);
        const filteredResponse = response?.data?.map((item: any) => {
            return item
        });

        setEpa(data);
        setSections(filteredResponse);
        setSectionIsDisabled(false);
        setCooperativeIsDisabled(true);
        setSection([]);
        setCooperative("");
    };

    const handleSectionInputChange = async (data: any) => {
        setSection(data);
        setCooperativeIsDisabled(false);
        setCooperative("");
    };

    const handleCooperativeInputChange = async (data: any) => {
        const { name, value } = data.target;
        setCooperative({ ...cooperative, [name]: value });
    };

    const submitChanges = (): any => {
        if (!isFormValid()) return;

        const data = {
            district,
            epa,
            ta,
            section,
            cooperative,
        };

        localStorage.setItem("app_settings", JSON.stringify(data));
        toast.current.show({ severity: 'success', summary: 'Info', detail: 'App Settings Updated' });
    };

    useImperativeHandle(ref, () => ({
        submitChanges,
    }));

    const isFormValid = () => {
        const newErrors: any = {};

        if (section?.length === 0 || section?.length === undefined) {
            newErrors.district = "District is required";
        }

        if (section?.length === 0 || section?.length === undefined) {
            newErrors.ta = "Traditional Authority is required";
        }

        if (epa?.length === 0 || epa?.length === undefined) {
            newErrors.epa = "EPA is required!";
        }

        if (section?.length === 0 || section?.length === undefined) {
            newErrors.section = "Section is required";
        }

        if (cooperative?.cooperative === "" || cooperative?.cooperative === undefined) {
            newErrors.cooperative = "Cooperative is required!";
        }

        setErrors(newErrors);

        return Object.keys(newErrors)?.length === 0;
    };

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Form.Group >

                <Form.Label className='form-labels'>District *</Form.Label>
                <Typeahead
                    clearButton
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleDistrictInputChange}
                    options={districts}
                    placeholder="Select District"
                    selected={district}
                    className='input pullErrorMessage'
                />

                <p className='errorMessage'> {errors.district && <span>{errors.district}</span>} </p>
                <hr />

                <Form.Label className='form-labels'>Traditional Authority *</Form.Label>
                <Typeahead
                    clearButton
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleTaInputChange}
                    options={tas}
                    placeholder="Select TA"
                    selected={ta}
                    className='input pullErrorMessage'
                    disabled={isTaDisabled}
                />

                <p className='errorMessage'> {errors.ta && <span>{errors.ta}</span>} </p>
                <hr />

                <Form.Label className='form-labels'>EPA *</Form.Label>
                <Typeahead
                    clearButton
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleEpaInputChange}
                    options={epas}
                    placeholder="Select EPA"
                    selected={epa}
                    className='input pullErrorMessage'
                    disabled={isEpaDisabled}
                />

                <p className='errorMessage'> {errors.epa && <span>{errors.epa}</span>} </p>
                <hr />

                <Form.Label className='form-labels'>Section *</Form.Label>
                <Typeahead
                    clearButton
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleSectionInputChange}
                    options={sections}
                    placeholder="Select Section"
                    selected={section}
                    className='input pullErrorMessage'
                    disabled={isSectionDisabled}
                />

                <p className='errorMessage'> {errors.section && <span>{errors.section}</span>} </p>
                <hr />

                <Form.Label className='form-labels'>Cooperative *</Form.Label>
                <Typeahead
                    clearButton
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={handleCooperativeInputChange}
                    options={sections}
                    placeholder="Select Section"
                    selected={section}
                    className='input pullErrorMessage'
                    disabled={isCooperativeDisabled}
                />

                <p className='errorMessage'> {errors.cooperative && <span>{errors.cooperative}</span>} </p>
        
            </Form.Group>
        </>
    );
});

export default SettingsComponent;

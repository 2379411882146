import CameraComponent from "./CameraComponent";
import './BiometricsComponent.styles.css'
import { RegistrationContext } from '../MembersComponents/MembersRegisterComponent'
import { useContext, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { withTranslation } from "react-i18next";

const BiometricsComponent = ({ onChange, t } : any) => {

    const [checked, setChecked]: any = useState<boolean>(false);

    const data: any = useContext(RegistrationContext)
    const handleInputChange  = async (image: any) => {
        onChange("photo", image)
    };

    return (
        <div className='container wrapper'>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="biometric-header-text">{t("eRegistration:consent_photo")}? <Checkbox onChange={(e: any) => setChecked(e.checked)} checked={checked}></Checkbox></p> 
                </div>
                {
                    checked ? 
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <h4 className="biometric-header">{t("eRegistration:capture_photo")}</h4>
                            <hr />
                            <CameraComponent data={data} onChange={handleInputChange}/>
                        </div> : <h1 className="biometric-header-text">{t("eRegistration:consent_photo_reminder")}</h1> 
                }
            </div>
        </div>
    );
};

export default withTranslation(["eRegistration", "common"]) (BiometricsComponent);
import { FC, useState } from "react";
import { ForumPost } from "../../types";

import styles from "./ViewForum.module.css";
import CommentContainer from "./CommentContainer";
import { Button } from "primereact/button";
import {
  Panel,
  PanelFooterTemplateOptions,
  PanelHeaderTemplateOptions,
} from "primereact/panel";
import { Avatar } from "primereact/avatar";
import dayjs from "../../lib/datetime";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useAuth } from "../../context/AuthContext";

type ViewForumProps = {
  post: ForumPost;
  commentsCount: number;
  setCommentsCount: (count: number) => void;
  deletePost: (id: string | number) => void;
};
const ViewForum: FC<ViewForumProps> = ({
  post,
  commentsCount,
  deletePost,
  ...rest
}) => {
  const { user } = useAuth();
  const footerTemplate = (options: PanelFooterTemplateOptions) => {
    const className = `${options.className} flex flex-wrap align-items-center justify-content-between gap-3`;
    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <Button
            icon="pi pi-user"
            rounded
            text
            label={`${commentsCount}`}
          ></Button>
        </div>
        <span className="p-text-secondary tex-xs">
          created {dayjs(post.created_at).fromNow()}
        </span>
      </div>
    );
  };

  // go back function
  const goBack = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return history.go(-1);
  }

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <ConfirmPopup />
        <div className="flex align-items-center gap-2">
          <Avatar
            label={post?.user.first_name[0]}
            size="normal"
            shape="circle"
          />
          <span className="font-bold text-sm">{post.title}</span>
        </div>
        <div className={styles.postHeaderMenus}>
          <button className="p-panel-header-icon p-link mr-2">
            <span className="pi pi-clock"></span>
          </button>
          {user?.id === post.user.id && (
            <button
              className="p-panel-header-icon p-link mr-2"
              onClick={(e) => {
                confirmPopup({
                  target: e.currentTarget,

                  message: "Are you sure you want to delete this post?",
                  icon: "pi pi-exclamation-triangle",
                  defaultFocus: "accept",
                  acceptClassName: "p-button-danger text",
                  accept: () => deletePost(post.id),
                });
              }}
            >
              <span className="pi pi-trash"></span>
            </button>
          )}

          {options.togglerElement}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.container}>

      {/* Go back container */}
      <div className="m-2">
        <button 
          onClick={goBack}
          className="p-2 me-2"
          style={{borderRadius: '50px', border: 'none', height:'50px', width: '50px'}}
        >
          <i className="pi pi-arrow-left"></i>
        </button>
        <span>Back</span>
      </div>


      <Panel headerTemplate={headerTemplate} footerTemplate={footerTemplate}>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>
          {/* <PostEditor
            className={styles.readOnlyEditor}
            value={post.content}
            readonly={true}
          /> */}
        </div>
      </Panel>
      <div className={styles.commentSection}>
        <CommentContainer post={post} {...rest} />
      </div>
    </div>
  );
};

export default ViewForum;

import { useState, createContext, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import BiometricsComponent from "../BiometricsComponents/BiometricsComponent";
import DemographicsComponent from "../SharedComponents/DemographicsComponents/DemographicsComponent";
import EnterpriseCompoent from "../EnterpriseComponents/EnterpriseComponent";
import AddressComponent from "../SharedComponents/AddressComponents/AddressComponent";
import ContactsComponent from "../SharedComponents/ContactsComponents/ContactsComponent";
import { Steps } from "primereact/steps";
import "./RegisterComponent.styles.css";
import { post, put } from "../../apiClient";
import { Demographics } from "../../types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { withTranslation } from "react-i18next";

export const RegistrationContext = createContext({});

const RegisterComponent = ({ onSubmit, editData, t }: any) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const items = [
    {
      label: "Demographics",
    },
    {
      label: "Location",
    },
    {
      label: "Biometrics",
    },
    {
      label: "Contacts",
    },
    {
      label: "Enterprises",
    },
  ];
  const [currentStep, setStep] = useState<number>(0);
  const [isDisabledSubmit, setDisableSubmit] = useState<boolean>(false);
  const [data, setRegistration] = useState<Demographics>(editData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const demographicsRef: any = useRef();
  const biometricsRef: any = useRef();
  const enterprisesRef: any = useRef();
  const addresssRef: any = useRef();
  const contactsRef: any = useRef();
  const toast: any = useRef(null);
  useEffect(() => {
    const { is_edit } = editData;
    const editMember = editData?.data;
    const currentMemberData = { ...data, ...editMember };
    setIsEdit(is_edit);
    setRegistration((currentState: any) => {
      return { ...currentMemberData, ...currentState };
    });
  }, []);
  const handleNext = () => {
    handleStep(currentStep);
  };
  const handleStep = (step: number, isStepperCliok: boolean = false) => {
    if (step === 0) {
      if(isStepperCliok){
        setStep(step);
      }
    }
    if (step === 1) {
      if ((!isDemographicsValid(data)) && isStepperCliok){
        validationErrorMessage("Check Error in Demographics section!");
        return;
      }else if(isStepperCliok){
        setStep(step);
      }else{
        const isValid = addresssRef?.current?.validateFrom();
        if(!isValid) return;
      }
    }
    
    if (step === 2) {
      const message = !isDemographicsValid(data) ? "Demographics" : "Location";
      if (!isDemographicsValid(data) || !isLocationValid(data)) {
        validationErrorMessage(`Check Error in ${message} section!`);
        return;
      }
    }

    if (step === 3) {
      if ((!isDemographicsValid(data) || !isLocationValid(data)) && isStepperCliok){
        const message = !isDemographicsValid(data) ? "Demographics" : "Location";
        validationErrorMessage(`Check Error in ${message} section!`);
        return;
      }else if(isStepperCliok){
        setStep(step);
      }else{
        const isValid = contactsRef?.current?.validateFrom();
        if(!isValid) return;
      }
    }
    if (step === 4) {
      const isValid = enterprisesRef?.current?.validateFrom(); 
      if(!isContactsValid(data) && isStepperCliok){
        validationErrorMessage("Check Error in Contacts section!");
        return;
      }
    }
    if (step === 4) setDisableSubmit(true);
    if(isStepperCliok) return setStep(step);
    if (step === 4) return;
    setStep(step + 1);
  }
  const handlePrevious = () => {
    if (currentStep === 0) return;
    setStep(currentStep - 1);
  };
  const handleChange = (field: any, value: any) => {
    setRegistration({ ...data, [field]: value });
  };
  const submit = async (e: any): Promise<any> => {
    e.preventDefault();
    const headers: any = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const payload = {
      ...data,
      district_id: data?.district?.id,
      epa_id: data?.epa?.id,
      section_id: data?.section?.id,
      traditional_authority_id: data?.traditional_authority?.id,
      cooperative_id: data?.cooperative?.id,
      village_id: data?.village?.id,
      enterprises: data?.enterprises?.map(enterprise => enterprise.id),
      user_type: user?.type
    };
    const updatePayload = {
      gender: data.gender,
      dob: data.dob,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      national_id: data.national_id,
      phone_number: data.phone_number,
      photo: data.photo,
      secondary_phone_number: data.secondary_phone_number,
      district_id: data?.district?.id,
      epa_id: data?.epa?.id,
      section_id: data?.section?.id,
      traditional_authority_id: data?.traditional_authority?.id,
      cooperative_id: data?.cooperative?.id,
      village_id: data?.village?.id,
      enterprises: data?.enterprises?.map(enterprise => enterprise.id),
    }
    setIsLoading(true);
    if (!isEdit) {
      const response = await post({ url: "members", headers, payload });
      if (response.status === 201) {
        //onSubmit(true)
        toast.current.show({
          severity: "success",
          summary: "Info",
          detail: "New Member Enrolled Successfully!",
        });
        navigate("/modules/members");
      } else {
        if (response?.response?.data?.code === 500) {
          const errors = response?.response?.data?.errors;
          const joinedErrors = errors.join(", ");
          toast.current.show({
            severity: "error",
            life: 10000,
            summary: "Failed creating a new member!",
            detail: `Error(s): ${joinedErrors?.split(": ")[1]}`,
          });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Info",
            detail: `Something Went Wrong, Reason [${response.code}]`,
          });
        }
      }
    }
    if (isEdit) {
      const response = await put(
        "members",
        headers,
        data?.id,
        updatePayload
      );
      if (response.status === 200) {
        onSubmit(true);
        toast.current.show({
          severity: "success",
          summary: "Info",
          detail: "Member Details Updated Successfully!",
        });
      } else {
        if (response?.response?.data?.code === 500) {
          const errors = response?.response?.data?.errors;
          const joinedErrors = errors.join(", ");
          toast.current.show({
            severity: "error",
            life: 10000,
            summary: "Failed updated member details!",
            detail: `Error(s): ${joinedErrors?.split(": ")[1]}`,
          });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Info",
            detail: `Something Went Wrong, Reason [${response.code}]`,
          });
        }
      }
    }
    setIsLoading(false);
  };

  //Validate Demographics
  const isDemographicsValid = (data: any) : any =>{
    return data.first_name !== "" 
      && data.last_name !== "" 
      && data.dob !=="" 
      && data.gender !=="" 
      && data.national_id !== "";
  }

  //Validate Location
  const isLocationValid = (data: any) : any =>{
    return data.district !== undefined 
      && data.traditional_authority !== undefined 
      && data.village !== undefined
      && data.epa !== undefined 
      && data.section !== undefined
      && data.cooperative !== undefined;
  }

  // Validate Contacts
  const isContactsValid = (data: any) : any =>{
    return data.phone_number !== "" 
      && data.email !== "";
  }

  const validationErrorMessage = (message: string): void => {
    toast.current.show({
      severity: "error",
      life: 10000,
      summary: "Validation Error",
      detail: `Error(s): ${message}`,
    });
  }

  return (
    <div className="">
      <Toast ref={toast} position="top-center" />
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-md-12 col-lg 12">
              <Steps
                model={items}
                activeIndex={currentStep}
                onSelect={(e) => handleStep(e.index, true)}
                readOnly={false}
              />
            </div>
          </div>
          <div className="steps-container">
            {isLoading ? (
              <div className="loader-margin">
                <ProgressBar
                  mode="indeterminate"
                  style={{ height: "6px" }}
                ></ProgressBar>
              </div>
            ) : (
              <RegistrationContext.Provider value={data}>
                {currentStep === 0 && (
                  <DemographicsComponent
                    ref={demographicsRef}
                    onChange={handleChange}
                  />
                )}
                {currentStep === 1 && (
                  <AddressComponent ref={addresssRef} onChange={handleChange} />
                )}
                {currentStep === 2 && (
                  <BiometricsComponent
                    ref={biometricsRef}
                    onChange={handleChange}
                  />
                )}
                {currentStep === 3 && (
                  <ContactsComponent
                    ref={contactsRef}
                    onChange={handleChange}
                  />
                )}
                {currentStep === 4 && (
                  <EnterpriseCompoent
                    ref={enterprisesRef}
                    onChange={handleChange}
                  />
                )}
              </RegistrationContext.Provider>
            )}
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <Button
                      label={t("common:back_button")}
                      severity="secondary"
                      outlined
                      onClick={handlePrevious}
                    disabled={isLoading || currentStep < 1}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <Button
                      label={t("common:next_label")}
                      severity="info"
                      onClick={handleNext}
                      disabled={isLoading || currentStep == 4}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <Button
                      label={t("common:registration_button")}
                      severity="success"
                      outlined
                      onClick={submit}
                      disabled={isLoading || currentStep != 4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(RegisterComponent);

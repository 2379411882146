//import OptionsData from "./options.json"
import CustomList from "../../components/SharedComponents/NavComponents/OptionsComponents/CustomList";
//import { useState, useEffect } from 'react'
//import { I18nextProvider, useTranslation } from 'react-i18next';
import { BreadCrumb } from "primereact/breadcrumb";
// import SidebarReactComponent from "../../components/SharedComponents/NavComponents/SidebarReactComponent";

const SettingsAppModule = () => {
  // const [languages, setLanguages] = useState<Array<any>>([]);

  // useEffect(() => {
  //     setLanguages([
  //         { id: 1, text: 'Chichewa', checked: false, icon: 'mw.png' },
  //         { id: 2, text: 'Tumbuka', checked: false, icon: 'mw.png' },
  //         { id: 3, text: 'English', checked: false, icon: 'gb.png' },
  //         // Add more items here
  // ]);
  // }, [])

  //console.log(I18nextProvider)

  //const [t]: any = useTranslation("settings_module");

  //const nav = t("nav_options", { returnObjects: true });

  const items = [
    { label: "Settings" },
    { label: "Language", url: "/modules/settings" },
    { label: "Locations", url: "/modules/settings/geo" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  return (
    <div className="app">
      <div className="main-content p-5 silver-line">
        <BreadCrumb model={items} home={home} />

        <div className="surface-0">
          <div className="surface-0">
            <div className="text-3xl font-medium text-900 mb-3">
              <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                  <div className="text-6xl text-primary font-bold mb-3">
                    Settings
                  </div>
                </div>
              </div>
            </div>
            <div>
              <CustomList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsAppModule;

import { ChangeEvent, FC, useState } from "react";
import UserProfile from "./UserProfile";
import { useAuth } from "../../context/AuthContext";
import { Spinner, Toast } from "react-bootstrap";
import { User } from "../../context/types";
import { api } from "../../apiClient";

type UserProfileContainerProps = {};

const UserProfileContainer: FC<UserProfileContainerProps> = ({}) => {
  // get user from AuthContext and set user sets user globally
  const { user: userObject, setUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUserProfile] = useState<User | null>(userObject);

  const handleUserUpdate = async () => {
    if (!user) return;

    setLoading(true);
    setError(null);
    try {
      const response = await api.patch(`users/${user?.id}/update`, {
        first_name: user?.first_name,
        last_name: user?.last_name,
        dob: user?.dob,
        email: user?.email,
        phone_number: user?.phone_number,
        gender: user?.gender,
        photo: user?.photo,
      });

      const updatedUser = response.data;

      setUser(updatedUser);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError("Failed to update profile. Please try again.");
      setLoading(false);
    }
    location.reload();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target;
    if (type === "file" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserProfile((prev: User | null) => ({
          ...(prev as User),
          photo: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setUserProfile((prev: User | null) => ({
        ...(prev as User),
        [name]: value,
      }));
    }
  };

  if (!user) {
    return <Spinner />;
  }

  return (
    <>
      {error && <div className="alert alert-danger">{error}</div>}
      <UserProfile
        profile={user}
        handleUpdate={handleUserUpdate}
        handleInputChange={handleInputChange}
        loading={loading}
      />
    </>
  );
};

export default UserProfileContainer;

import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { FC } from "react";

const ProtectedRoute: FC<{ children?: any; redirectTo: string }> = ({
  children,
  redirectTo,
}) => {
  const auth = useAuth();
  const location = useLocation();

  // Redirect to login if user is not authenticated
  if (!auth.user && location.pathname !== "/modules/advisories" ) {
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

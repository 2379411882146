import { Form } from 'react-bootstrap';
import { useContext, useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { RegistrationContext } from '../MembersComponents/MembersRegisterComponent'
import { get } from "../../apiClient";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface Enterprise {
    id: number;
    name: string;
}

const DemographicsComponent = forwardRef(({ onChange }: any, ref: any) => {

    const data: any = useContext(RegistrationContext);
    
    useEffect(()=> {
        getListOfEnterprises();
        if (data?.enterprises !== undefined) setSelectedEnterprise(data?.enterprises);
    }, [])

    async function getListOfEnterprises() {
        const response = await get('enterprises');
        const data = response.data.map((item: any) => {
            return {
                id: item.id,
                name: item.name
            }
        });
        setListOfEnterprises(data);
    }

    const [errors, setErrors]: any = useState({});

    const [enterprises, setListOfEnterprises] = useState<Array<Enterprise>>([])
    const [selectedEnterprises, setSelectedEnterprise] = useState<Enterprise | null>(null);

    const handleInputChange = async (data: any) => {
        setSelectedEnterprise(data);
        onChange("enterprises", data)
    };

    const validateFrom = () => {
        const newErrors: any = {};

        if (!data?.enterprise?.trim()) {
            newErrors.enterprise = 'Enterprise required!';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    useImperativeHandle(ref, () => ({
        validateFrom,
    }));

    return (
        <>
            <hr />
            <Form.Group>
                <MultiSelect value={selectedEnterprises} onChange={(e: MultiSelectChangeEvent) => handleInputChange(e.value)} options={enterprises} optionLabel="name" 
                    placeholder="Select Enterprise(s)" maxSelectedLabels={3} className="w-full md:w-100rem" />
                <p className='errorMessage'> {errors.enterprise && <span>{errors.enterprise}</span>} </p>
            </Form.Group>
            <hr />
        </>
    );
});

export default DemographicsComponent;
import { useState, useRef } from "react";
import "./option.css";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";

function CustomList() {
  const toast: any = useRef(null);

  const [_, i18n]: any = useTranslation("nav");

  const [items, setItems] = useState<Array<any>>([
    { id: 1, text: "English", code: "english", checked: false, icon: "gb.png" },
    { id: 2, text: "Tumbuka", code: "tumbuka", checked: false, icon: "mw.png" },
    {
      id: 3,
      text: "Chichewa",
      code: "chichewa",
      checked: false,
      icon: "mw.png",
    },
  ]);

  const handleItemClick = (id: any) => {
    setItems(
      items?.map((item) => {
        if (item.id === id) {
          i18n.changeLanguage(item?.code);
          toast.current.show({
            severity: "success",
            summary: "Language Settings",
            detail: `Switched to ${item.text} mode!`,
          });
          localStorage.setItem("language_settings", item.code);
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      })
    );
  };

  return (
    <div id="customList">
      <Toast ref={toast} position="top-center" />
      <div className="row">
        {items?.map((item) => (
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div key={item.id} onClick={() => handleItemClick(item.id)}>
              <input type="checkbox" checked={item.checked} readOnly />
              <label className="option-label">
                <img
                  alt="profile"
                  width="20%"
                  src={process.env.PUBLIC_URL + `/modules_images/${item.icon}`}
                  style={{ cursor: "pointer", borderRadius: "5%" }}
                  className="language-image"
                />{" "}
                {item.text}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomList;

import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./ArticleActionButton.module.css";
import { ActionOption, Category } from "../types";
import { withTranslation } from "react-i18next";

type ArticleActionButtonProps = {
  category: Category;
  handleViewOption: (option: ActionOption) => void;
  t: any;
};

const ArticleActionButton: FC<ArticleActionButtonProps> = ({
  category,
  handleViewOption,
  t
}) => {
  return (
    <div className={styles.container}>
      <Dropdown className={styles.dropdown}>
        <Dropdown.Toggle
          variant="primary"
          id="dropdown-basic"
          className={styles.dropdownToggle}
        >
          {t("common:filter_actions")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleViewOption(ActionOption.Categories)}
          >
            {t("common:view_content")}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleViewOption(ActionOption.CreateArticle)}
          >
            {t("common:create_content")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default withTranslation(["advisories", "common"])(ArticleActionButton);

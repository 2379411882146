import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { Avatar } from "primereact/avatar";
import { useRef } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SideBarMenuItems } from "../../../constants/sidebar";
import { SideBarMenuModules } from "../../../constants/sidebarModules";

const SidebarReactComponent = ({ t, isChild, id }: any): any => {
  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const Auth = useAuth();
  const user = Auth.user;
  //work around
  // if (typeof transition !== "object") return null;
  const childModule: string = isChild ? `read/${id}` : "";

  const Item = () => {
    return (
      <>
        <StyleClass
          nodeRef={btnRef1}
          selector="@next"
          enterClassName="hidden"
          enterActiveClassName="slidedown"
          leaveToClassName="hidden"
          leaveActiveClassName="slideup"
        >
          <div
            ref={btnRef1}
            className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
          >
            <span className="font-medium fw-bolder fs-5">{t("actions")}</span>
            <i className="pi pi-chevron-down"></i>
            <Ripple />
          </div>
        </StyleClass>
        <ul className="list-none p-0 m-0 overflow-hidden">
          {SideBarMenuItems.map((item: any, index: number) => (
            <Link key={index} to={item.to}>
              <li
                key={index}
                className="p-ripple align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
              >
                <i className={item.icon}></i>
                <span className="ms-2 font-medium">{t(item.title)}</span>
                <Ripple />
              </li>
            </Link>
          ))}
        </ul>
      </>
    );
  };

  const Modules = () => {
    return (
      <>
        <StyleClass
          nodeRef={btnRef2}
          selector="@next"
          enterClassName="hidden"
          enterActiveClassName="slidedown"
          leaveToClassName="hidden"
          leaveActiveClassName="slideup"
        >
          <div
            ref={btnRef2}
            className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
          >
            <span className="font-medium fw-bolder fs-5">
              {/* {transition.modules} */}
              {t("modules")}
            </span>
            <i className="pi pi-chevron-down"></i>
            <Ripple />
          </div>
        </StyleClass>

        <ul className="list-none p-0 m-0 overflow-hidden">
          {SideBarMenuModules.map((item: any, index: number) => (
            <Link key={index} to={item.to}>
              <li
                key={index}
                className="p-ripple align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
              >
                <i className={item.icon}></i>
                <span className="ms-2 font-medium">{t(item.title)}</span>
                <Ripple />
              </li>
            </Link>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div
      className="min-h-screen flex lg:static surface-ground"
      style={{ borderRadius: "1rem" }}
    >
      <div
        id="app-sidebar-2"
        className="surface-section h-screen hidden lg:block lg:static border surface-border select-none"
        style={{
          width: "280px",
          borderRadius: "1rem",
          boxShadow:
            "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="flex flex-column h-full">
          {/* <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
            <span className="inline-flex align-items-center gap-2">
              <span className="font-semibold text-2xl text-primary"></span>
            </span>
          </div> */}
          <div className="overflow-y-auto">
             <ul className="list-none p-3 m-0">
              {/* <Link
                to={`${transition.parent_module}/${transition.module_url}/${childModule}`}
              >
                <li className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                  <i className={`fw-bold fs-5 me-2 ${transition.logo}`}></i>
                  <span className="fw-bolder" style={{ fontSize: "1.2rem" }}>
                    {transition.module_name}
                  </span>
                  <Ripple />
                </li>
              </Link> */}

              <li>{Item()}</li>
              <li>{Modules()}</li>
            </ul> 
          </div>
          <div className="mt-auto">
            <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
            <Link
              to="/profile"
              v-ripple
              className="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
            >
              <Avatar
                image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
                shape="circle"
              />
              <span className="font-bold">
                {user?.first_name} {user?.last_name}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("sidebar")(SidebarReactComponent);

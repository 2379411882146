import { Button } from "primereact/button";
import { ChangeEvent, FC, useEffect, useState } from "react";
import TextInput from "./components/TextInput";
import { Dialog, DialogProps } from "primereact/dialog";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { GENDER_OPTIONS, RELATIONSHIP_OPTIONS } from "./constants";
import { Dropdown } from "primereact/dropdown";
import styles from "./Household.module.css";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { HouseholdMember } from "../../types";
import { withTranslation } from "react-i18next";

type FormData = {
  first_name: string;
  last_name: string;
  gender: string;
  relationship: string;
  national_id: string;
  canCollapse?: boolean;
  dob: string;
  has_disabilities?: boolean;
  farming_participant?: boolean;
};

const EMPTY_FORM_DATA: FormData = {
  first_name: "",
  last_name: "",
  gender: "",
  relationship: "",
  national_id: "",
  canCollapse: false,
  dob: "",
  has_disabilities: false,
  farming_participant: false,
};

type HouseholdMemberFormProps = {
  visible: boolean;
  update?: boolean;
  member?: HouseholdMember;
  setVisible: (visible: boolean) => void;
  handleCreateHouseholdMember: (params: any) => void;
  handleUpdateMember: (id: string | number, params: any) => void;
  onClose?: () => void;
  t: any;
};
const HouseholdMemberForm: FC<HouseholdMemberFormProps> = ({
  visible,
  member,
  update = false,
  setVisible,
  handleCreateHouseholdMember,
  handleUpdateMember,
  t
}) => {
  useEffect(() => {
    if (update) {
      setFormData([member as FormData]);
    }
  }, [member, update]);

  const [formData, setFormData] = useState<FormData[]>([EMPTY_FORM_DATA]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [errors, setErrors] = useState<{ [key: string]: any }[]>([
    {
      first_name: "",
      last_name: "",
      gender: "",
      relationship: "",
      national_id: "",
    },
  ]);

  const handleAddNewMember = () => {
    if (!validateForm(currentIndex)) return;
    const currentData = formData.slice();
    const currentErrors = errors.slice();
    currentData.push(EMPTY_FORM_DATA);
    currentErrors.push(EMPTY_FORM_DATA);
    currentData[currentIndex].canCollapse = true;
    setFormData(currentData);
    setErrors(currentErrors);
    setCurrentIndex(currentData.length - 1);
  };

  const handleRemove = () => {
    const currentData = formData.slice();
    const currentErrors = errors.slice();
    currentData.pop();
    currentErrors.pop();
    const newIndex = currentData.length - 1;

    currentData[newIndex].canCollapse = false;
    setFormData(currentData);
    setErrors(currentErrors);
    setCurrentIndex(newIndex);
  };

  const handleCheckBoxChange = (index: number) => (e: CheckboxChangeEvent) => {
    const { name, value, checked } = e.target as HTMLInputElement;
    setFormData((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [name]: checked } : item))
    );
  };

  const handleChange =
    (index: number) => (e: ChangeEvent | SelectButtonChangeEvent) => {
      const { name, value } = e.target as HTMLInputElement;

      setFormData((prev) =>
        prev.map((item, i) => (i === index ? { ...item, [name]: value } : item))
      );

      setErrors((prev: { [key: string]: string }[]) =>
        prev.map((item: { [key: string]: string | boolean }, i) =>
          i === index ? { ...item, [name]: "" } : item
        )
      );
      setErrors(errors);

      return Object.keys(errors).length === 0;
    };

  const validateForm = (index: number) => {
    const errorObject: { [key: string]: string } = {};

    if (formData[index].first_name === "") {
      errorObject["first_name"] = `${t("common:first_name_required")}`;
    }
    if (formData[index].last_name === "") {
      errorObject["last_name"] = `${t("common:last_name_required")}`;
    }
    if (formData[index].gender === "") {
      errorObject["gender"] = `${t("common:gender_required")}`;
    }
    if (formData[index].relationship === "") {
      errorObject["relationship"] = `${t("common:relation_required")}`;
    }
    if (formData[index].national_id === "") {
      errorObject["national_id"] = `${t("common:national_id_required")}`;
    }
    if (formData[index].dob === "") {
      errorObject["dob"] = `${t("common:dob_required")}`;
    }
    const currentErrors = errors.slice();
    currentErrors[index] = errorObject;
    setErrors(currentErrors);

    return Object.keys(errorObject).length === 0;
  };

  const handeSubmit = () => {
    if (!validateForm(currentIndex)) return;
    if (update && member) {
      const updateParams = {
        first_name: formData[0].first_name,
        last_name: formData[0].first_name,
        gender: formData[0].gender,
        relationship: formData[0].relationship,
        national_id: formData[0].national_id,
        dob: formData[0].dob,
        has_disabilities: formData[0].has_disabilities,
        farming_participant: formData[0].farming_participant,
      };
      handleUpdateMember(member?.id, updateParams);
      setVisible(false);
      setFormData([EMPTY_FORM_DATA]);
      return;
    } else {
      handleCreateHouseholdMember(formData);
      setVisible(false);
    }
  };

  const footerContent = (props: DialogProps) => {
    return (
      <div className="flex gap-2 justify-content-end">
        {currentIndex > 0 && (
          <Button
            label={`${t("common:remove_label")}`}
            size="large"
            style={{ width: "150px" }}
            icon="pi pi-times"
            severity="danger"
            onClick={handleRemove}
            className="rounded"
          />
        )}
        {!update && (
          <Button
            label={`${t("common:new_label")}`}
            size="large"
            style={{ width: "150px" }}
            icon="pi pi-times"
            severity="info"
            onClick={handleAddNewMember}
            className="rounded"
          />
        )}
        <Button
          label={update ? `${t("common:edit_label")}` : `${t("common:save_label")}`}
          size="large"
          style={{ width: "150px" }}
          icon="pi pi-save"
          severity="success"
          onClick={handeSubmit}
          className="rounded"
        />
      </div>
    );
  };

  return (
    <Dialog
      draggable={false}
      header={`${update ? t("eRegistration:update_household_member") : t("eRegistration:create_new_member")}`}
      headerStyle={{
        textAlign: "center",
      }}
      visible={visible}
      style={{ width: "35vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
    >
      {formData
        .filter((form) => form.canCollapse)
        .map((form, index) => {
          return (
            <div className="flex justify-content-center">
              <CollapsedMemberForm form={form} key={index} />
            </div>
          );
        })}

      {formData &&
        formData
          .filter((f) => !f.canCollapse)
          .map((form, index) => {
            return (
              <div className="col-10 flex flex-column gap-2 m-auto" key={index}>
                <TextInput
                  label={`${t("common:first_name")}`}
                  name="first_name"
                  value={form.first_name}
                  onChange={handleChange(index)}
                  error={errors[index]?.first_name}
                />
                <TextInput
                  label={`${t("common:first_name")}`}
                  name="last_name"
                  value={form.last_name}
                  onChange={handleChange(index)}
                  error={errors[index]?.last_name}
                />
                <TextInput
                  label={`${t("common:national_id_label")}`}
                  name="national_id"
                  value={form.national_id}
                  onChange={handleChange(index)}
                  error={errors[index]?.national_id}
                />
                <TextInput
                  label={`${t("common:date_of_birth")}`}
                  name="dob"
                  type="date"
                  value={form.dob}
                  onChange={handleChange(index)}
                  error={errors[index]?.dob}
                />
                <SelectButton
                  optionLabel="label"
                  options={GENDER_OPTIONS}
                  onChange={handleChange(index)}
                  name="gender"
                  value={form.gender}
                />
                {errors[index]?.gender && (
                  <span className="text-sm text-danger">
                    {errors[index]?.gender}
                  </span>
                )}
                <Dropdown
                  value={form?.relationship}
                  options={RELATIONSHIP_OPTIONS}
                  className={styles.dropdown}
                  name="relationship"
                  onChange={handleChange(index)}
                />
                {errors[index]?.relationship && (
                  <span className="text-sm text-danger">
                    {errors[index]?.relationship}
                  </span>
                )}

                <div className="flex align-items-center gap-2">
                  <label htmlFor="ingredient2" className="ml-2">
                    {t("common:disability_label")}
                  </label>
                  <Checkbox
                    name="has_disabilities"
                    value={form.has_disabilities}
                    onChange={handleCheckBoxChange(index)}
                    checked={!!form?.has_disabilities}
                  />
                </div>

                <div className="flex align-items-center gap-2">
                  <label htmlFor="ingredient2" className="ml-2">
                    {t("common:is_farmer")}
                  </label>
                  <Checkbox
                    name="farming_participant"
                    onChange={handleCheckBoxChange(index)}
                    value={form.farming_participant}
                    checked={!!form.farming_participant}
                  />
                </div>
              </div>
            );
          })}
    </Dialog>
  );
};

const CollapsedMemberForm: FC<{ form: FormData }> = ({ form }) => {
  return (
    <div className="p-2 m-auto mb-2">
      <div className={styles.collapsedContaner}>
        <p className="text-sm mt-3">
          {form.first_name.slice(0, 15).length >= 15
            ? form.first_name.slice(0, 15) + "..."
            : form.first_name}
        </p>
        {/* <p>First name: {form.last_name}</p>
        <p>Gender: {form.gender}</p>
        <p>Relation: {form.relationship}</p> */}
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(HouseholdMemberForm);

import EditorComponent from '../../../components/AdvisoryServicesComponents/EditorComponent'
import { useState, useRef, useEffect } from 'react';
import { post, put, get } from '../../../apiClient';
import { useParams } from 'react-router-dom';
import { ArticlePayload } from "../types";
import { Toast } from "primereact/toast";
import { Form } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

const Create = ({ notifyOnCreated, isEdit, article, t }: any): any => {
    const toast: any = useRef(null);
    const [title, setTitle] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const headers: any = {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    const { id, article_id } : any = useParams();
    const [blog, setBlog] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        getArticleById(id);
        const artickle = article?.is_update ? article?.content : ''
        setTitle(article?.title)
        setBlog(artickle)
        setIsLoading(false);
    }, [])

    async function handleBlogInput(data: any){
        const payload: ArticlePayload = {
            richContent: data,
            category_id: parseInt(id!),
            tags: [""],
            title
        }

        const articlePayload: ArticlePayload = {
            id: article?.id,
            richContent: data,
            title
        }

        const response: any = isEdit ? await updateBlog(articlePayload) : await saveBlog(payload);

        if (response.status === 200) {
            toast.current.show({
                severity: "success",
                summary: "Content Creation",
                detail: `Content Page Added`,
            });
            notifyOnCreated(true)
        } else {
            toast.current.show({
                severity: "warn",
                summary: "Info",
                detail: `Somehting Went Wrong: Failed Create Content`,
            });
            notifyOnCreated(false)
        }
        setBlog(blog);
    }

    async function getArticleById (id: number): Promise<any> {
        return await get('articles', headers, id);
    }

    async function saveBlog(payload: ArticlePayload): Promise<any> {
        return await post({url: 'articles', headers, payload });
    }

    async function updateBlog(payload: ArticlePayload): Promise<any> {
        const response = await put('articles', headers, article?.id, payload);
        return await put('articles', headers, article?.id, payload);
    }

    const handleTitleInput = (event:any) => {
        const { value } = event.target;
        if (event.target === undefined) return;
        setTitle(value);
    }

    return (

        <div className='module-wrapper'>
            <Toast ref={toast} position="top-center" />
            <div className="module-container w-75 mt-3">
                <div className="card">
                    <h4>{article?.is_update ? t("common:update_content") : t("common:create_content") }</h4>
                    <Form.Group>
                        <Form.Label> {t("common:title_label")} </Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            className="input"
                            value={title || ""}
                            onChange={handleTitleInput}
                        />
                    </Form.Group>
                    { !isLoading ? <EditorComponent emitData={handleBlogInput} propData={blog} isEdit={article?.is_update}/> : '' }
                </div>
            </div>
        </div>
    );
};

export default withTranslation(["advisories", "common"])(Create);
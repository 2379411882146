import { FC, useEffect, useState } from "react";
import UserList from "./UserList";
import { getUsersList } from "../endpoints";
import { User } from "../../context/types";

const UserContainer: FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    setLoading(true);
    getUsersList()
      .then((res) => {
        setLoading(false);
        setUsers(res.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  return <UserList users={users} />;
};

export default UserContainer;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../apiClient";
import { ForumPost } from "../../types";
import ViewForum from "./ViewForum";
import { deletePost } from "../endpoints";
import { ProgressBar } from "primereact/progressbar";

type ViewForumContainerProps = {};

const ViewForumContainer = (props: ViewForumContainerProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState<ForumPost | null>(null);
  const [commentsCount, setCommentsCount] = useState<number>(0);

  const handleDeletePost = (id: string | number) => {
    deletePost(id).then(() => {
      navigate("/modules/messaging");
    });
  };

  useEffect(() => {
    const fetchForumPost = async (id: string | undefined) => {
      api
        .get(`/posts/${id}`)
        .then((response) => {
          setPost(response.data);
          setCommentsCount(response.data.comments_count);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchForumPost(id);
  }, []);

  if (!post) {
    return (
      <ProgressBar mode="indeterminate" style={{ height: "6px" }}></ProgressBar>
    );
  }

  return (
    <ViewForum
      post={post}
      deletePost={handleDeletePost}
      commentsCount={commentsCount}
      setCommentsCount={setCommentsCount}
    />
  );
};

export default ViewForumContainer;

import { FC } from "react";
import { Category } from "./types";

import styles from "./style.module.css";
import { Link } from "react-router-dom";

const CategoryCard: FC<{
  data: Category;
  handleCheckboxChange: (data: Category) => void;
  allowRefresh?: () => void
}> = ({ data, handleCheckboxChange, allowRefresh }) => {
  const icon: string =
    data.image || process.env.PUBLIC_URL + `/advisories_images/folder-1.png`;

  return (
    <div className="col-lg-2 col-md-6 col-sm-6 col-lg-3">
      <div className={styles.moduleContainer}>
        <Link
          to={`/modules/advisories/read/${data.id}`}
            onClick={allowRefresh}
        >
          <div className={styles.moduleOption}>
            <img
              alt="profile"
              src={icon}
              style={{ cursor: "pointer" }}
              className={styles.moduleOptionIcon}
            />
            <div className={styles.moduleName}>{data.name}</div>
          </div>
        </Link>
        <div className={styles.checboxContainer}>
          <label className="container-advisory">
            <i
              className="pi pi-ellipsis-v mr-2 module-option-hover"
              onClick={() =>{
                handleCheckboxChange(data)
              }}
            ></i>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;

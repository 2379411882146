import { FC, useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import { Button } from 'primereact/button';
import pica from 'pica';
import { withTranslation } from 'react-i18next';

interface EditorComponentProps {
  emitData?: (data: string) => void;
  propData?: string;
  isEdit?: boolean;
  t: any;
}

const EditorComponent: FC<EditorComponentProps> = ({ emitData, propData = '', isEdit = false, t }) => {
  const [value, setValue] = useState<string>(propData);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {},
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        ['image'],
        [{ align: [] }],
      ],
    },
  });

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.on('text-change', handleInputChange);
      if (propData) {
        processHtml(propData).then((processedHtml) => {
          quill.clipboard.dangerouslyPasteHTML(processedHtml);
        });
        setValue(propData);
      }
    }
    setIsLoading(false);
  }, [quill, propData]);

  const handleInputChange = (): void => {
    if (!quill) return;
    const innerHtml = quill.root.innerHTML;
    setValue(innerHtml);
  };

  const processHtml = async (html: string): Promise<string> => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const images = doc.querySelectorAll('img');
    for (const img of images) {
      if (img.src.startsWith('data:image/')) {
        const resizedSrc = await resizeImage(img.src);
        img.src = resizedSrc;
      }
    }
    return doc.body.innerHTML;
  };

  const resizeImage = async (src: string): Promise<string> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 800;
        let scaleSize = 1;

        if (img.width > MAX_WIDTH) {
          scaleSize = MAX_WIDTH / img.width;
        }

        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = canvas.width;
          resizedCanvas.height = canvas.height;

          await pica().resize(canvas, resizedCanvas);

          resizedCanvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result as string);
            };
            if (blob) {
              reader.readAsDataURL(blob);
            }
          }, 'image/jpeg', 0.6);
        }
      };
    });
  };

  const saveBlog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (emitData)
      processHtml(value).then((processedHtml) => {
        emitData(processedHtml);
      });
  };

  return (
    <div>
      <div className="editor-wrapper">
        <div ref={quillRef} className='editor-container' />
        <div className='save-page-button'>
          <Button label={isEdit ? `${t("common:edit_label")}` : `${t("common:save_label")}`} onClick={saveBlog} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["advisories", "common"]) (EditorComponent);

import { Form } from "react-bootstrap";
import { useState, useEffect, useRef, SetStateAction } from "react";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import HeaderCompomemt from "../SharedComponents/NavComponents/HeaderComponent";
import "./RegisterUserComponent.styles.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { Toast } from "primereact/toast";
import ERRORS from "../../ErrorMessages";
import { ProgressBar } from "primereact/progressbar";
import { api, get } from "../../apiClient";

interface User {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  phone_number: string;
  language_preference: string;
  district_id: number;
  user_type: string;
}

const RegisterUserComponent = (): any => {
  const toast: any = useRef(null);
  const navigate = useNavigate();
  const [details, setUserDetails] = useState<any>({});
  const [districts, setDistricts] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);
  const [errors, setErrors]: any = useState({});
  const [userRoles, setRoles] = useState<{ name: string; id: number }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleInputChange = (event: any): void => {
    const { name, value } = event.target;
    if (event.target === undefined) return;
    setUserDetails({ ...details, [name]: value });
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await get("districts");
      const districtsData = response?.data || [];

      // Sort districts alphabetically by name
      const sortedDistricts = districtsData.sort(
        (a: { name: string }, b: { name: any }) => {
          return a.name.localeCompare(b.name);
        }
      );

      // Update state with sorted districts
      setDistricts(sortedDistricts);

      // Retrieve and set selected district from local storage
      const settingsString: any = localStorage.getItem("app_settings");
      const settings = JSON.parse(settingsString);
      setDistrict(settings?.district);
    };
    const fetRoles = async () => {
      api.get("roles").then((res) => {
        setRoles(res.data);
      });

      // Update state with sorted districts
    };
    fetchDistricts();
    fetRoles();
  }, []);
  const handleDistrictInputChange = async (data: any) => {
    setDistrict(data);
  };

  const submit = async (e: any) => {
    e.preventDefault();
    if (!isFormValid())
      return toast.current.show({
        severity: "error",
        summary: "Missing required field(s)",
        detail: "Required fields must be completed correctly",
      });
    setIsLoading(true);
    const user: User = {
      ...details,
      district_id: district[0]?.id,
    };
    api
      .post("auth/register/users", user)
      .then(() => {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Info",
          detail: "Create user account request sent successfully!",
        });
        setTimeout(() => {
          navigate("/login");
        }, 500);
      })
      .catch((err) => {
        setIsLoading(false);

        if (ERRORS.includes(err?.code))
          return toast.current.show({
            severity: "warn",
            summary: "Info",
            detail: `${err?.code}!: ${err?.message}`,
          });
      });
  };
  const isFormValid = () => {
    const newErrors: any = {};
    if (details.first_name === "" || details.first_name === undefined) {
      newErrors.first_name = "First Name is required";
    }
    if (details.last_name === "" || details.last_name === undefined) {
      newErrors.last_name = "Last Name is required";
    }
    if (details.email === "" || details.email === undefined) {
      newErrors.email = "Email is required";
    }
    if (details.email === "" || details.email === undefined) {
      newErrors.email = "Email is required";
    }
    if (details.phone_number === "" || details.phone_number === undefined) {
      newErrors.phone_number = "Phone Number is required";
    }
    if (details.password === "" || details.password === undefined) {
      newErrors.password = "Password is required";
    }
    if (
      details.password_confirm === "" ||
      details.password_confirm === undefined
    ) {
      newErrors.password_confirm = "Confirm password is required";
    }
    if (details.password !== details.password_confirm) {
      newErrors.password_confirm = "Passwords do not match!";
    }
    if (district?.length === 0 || district?.length === undefined) {
      newErrors.district = "District is required";
    }
    if (details.language_preference !== details.language_preference) {
      newErrors.password_confirm = "Language Preference is required!";
    }
    if (!details.user_type) {
      newErrors.user_type = "User role required!";
    }

    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  return (
    <>
      <div>
        <Toast ref={toast} position="top-center" />
        <HeaderCompomemt />

        <br />

        {isLoading ? (
          <div className="loader-margin-registration">
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          </div>
        ) : (
          <div
            className="flex align-items-center justify-content-center"
            style={{ height: "75vh" }}
          >
            <div className="surface-card p-4 shadow-2 border-round lg:w-6 sm:w-9 xs:w-9">
              <div className="text-center mb-5">
                <div className="text-900 text-3xl font-medium mb-3">
                  Request User Account
                </div>
                <span className="text-600 font-medium line-height-3">
                  Already have an account?
                </span>
                <a className="font-medium no-underline ml-1 text-blue-500 cursor-pointer">
                  <Link to="/login">Log in</Link>
                </a>
              </div>

              <div className="row">
                <Form.Group controlId="formStep1" className="row">
                  <div className="col-lg-6 col-xs-12">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      className="input"
                      value={details.first_name}
                      onChange={handleInputChange}
                    />

                    <p className="errorMessage">
                      {" "}
                      {errors.first_name && (
                        <span>{errors.first_name}</span>
                      )}{" "}
                    </p>

                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      className="input"
                      value={details.last_name}
                      onChange={handleInputChange}
                    />
                    <p className="errorMessage">
                      {" "}
                      {errors.last_name && <span>{errors.last_name}</span>}{" "}
                    </p>
                    <Form.Label>Password *</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      className="input"
                      value={details.password}
                      onChange={handleInputChange}
                    />
                    <p className="errorMessage">
                      {" "}
                      {errors.password && <span>{errors.password}</span>}{" "}
                    </p>
                    <Form.Label>Confirm Password *</Form.Label>
                    <Form.Control
                      type="password"
                      name="password_confirm"
                      className="input"
                      value={details.password_confirm}
                      onChange={handleInputChange}
                    />
                    <p className="errorMessage">
                      {" "}
                      {errors.password_confirm && (
                        <span>{errors.password_confirm}</span>
                      )}{" "}
                    </p>
                  </div>
                  <div className="col-lg-6 col-xs-12">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      className="input"
                      value={details.email}
                      onChange={handleInputChange}
                    />
                    <p className="errorMessage">
                      {" "}
                      {errors.email && <span>{errors.email}</span>}{" "}
                    </p>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      className="input"
                      value={details.phone_number}
                      onChange={handleInputChange}
                    />
                    <Form.Label>District *</Form.Label>
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={handleDistrictInputChange}
                      options={districts}
                      placeholder="Select District"
                      selected={district}
                      className="input pullErrorMessage"
                    />
                    <p className="errorMessage">
                      {" "}
                      {errors.district && <span>{errors.district}</span>}{" "}
                    </p>
                    {/* <Form.Label>User Roles *</Form.Label>
                      <Typeahead

                          id="basic-typeahead-single"
                          labelKey="name"
                          options={userroles}
                          placeholder="User Role"
                          selected={userroles}
                          className='input pullErrorMessage'
                      />
                      <p className='errorMessage'> {errors.district && <span>{errors.district}</span>} </p> */}
                    <div className="form-group">
                      <Form.Label>User Role *</Form.Label>
                      <select
                        className="form-control input"
                        name="user_type"
                        id="userRole"
                        value={details.user_type}
                        onChange={handleInputChange}
                        style={{ marginTop: "5px" }}
                      >
                        <option value={""} disabled selected>
                          Please select user role
                        </option>
                        {userRoles.map((role) => {
                          return <option value={role.id}>{role.name}</option>;
                        })}
                      </select>
                      <p className="errorMessage">
                        {errors?.user_type && <span>{errors?.user_type}</span>}{" "}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="">
                    <div className="">
                      <Button
                        label="Submit user account request!"
                        severity="info"
                        onClick={submit}
                        className="w-full"
                      />
                      
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterUserComponent;

import { FC } from "react";
import Search from "./search";
import "./messaging.css";
import CreatePost from "./CreatePostFrom";
import { useForum } from "./context";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import styles from "./Forum.module.css";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import {
  Panel,
  PanelFooterTemplateOptions,
  PanelHeaderTemplateOptions,
} from "primereact/panel";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import dayjs from "../../lib/datetime";
import { ForumPost } from "../../types";
import { useAuth } from "../../context/AuthContext";
import { withTranslation } from "react-i18next";

type ForumProps = {t: any};

const Forum: FC<ForumProps> = ({t}) => {
  const { posts, limit, offset, count, deletePost, handlePageChange } =
    useForum();

  return (
    <div className="silver-line p-5">
      <h1>{t("forum:forum_header")}</h1>
      <div className="flex justify-content-between"></div>

      <div className={styles.postsContainer}>
        <div className="flex justify-content-between">
          <Search />
          <CreatePost />
        </div>

        <div className="flex flex-column gap-2 mt-5">
          {posts.map((post: ForumPost, index: number) => {
            return <PostRow key={index} post={post} deletePost={deletePost} t={t} />;
          })}
        </div>
      </div>

      <Paginator
        first={offset}
        rows={limit}
        totalRecords={count}
        onPageChange={(e) => {
          handlePageChange(e.first);
        }}
        template={{ layout: "PrevPageLink CurrentPageReport NextPageLink" }}
      />
    </div>
  );
};

export default withTranslation(["common","forum"])(Forum);

const PostRow: FC<{
  post: ForumPost;
  deletePost: (id: string | number) => void;
  t: any
}> = ({ post, deletePost, t }) => {
  const { user } = useAuth();
  const footerTemplate = (options: PanelFooterTemplateOptions) => {
    const className = `${options.className} flex flex-wrap align-items-center justify-content-between p-1 rounded`;
    return (
      <div
        className={className}
        style={{
          borderTop: "none",
        }}
      >
        <div className="flex align-items-center gap-2 text-green-700">
          <Button
            className="text-blue-700"
            icon="pi pi-user text-blue-700"
            rounded
            text
            label={`${post.comments_count}`}
          />
        </div>
        <span className="p-text-secondary text-xs text-color">
          {t("common:created_label")} {dayjs(post.created_at).fromNow()}
        </span>
      </div>
    );
  };
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} ${styles.postHeader} justify-content-space-between`;

    return (
      <div className={className}>
            <ConfirmPopup />
            <Link to={`/modules/messaging/${post.id}`}>
              <div className="flex align-items-center gap-2">
                {post.user && (
                  <div className={styles.avatarSection}>
                    <Button
                      label={post.user.first_name[0]}
                      className={styles.avatar}
                      tooltip={post.user.first_name + " " + post.user.last_name}
                    />
                  </div>
                )}
                <span className="font-bold text-sm">{post.title}</span>
              </div>
              </Link>
              <div className={styles.postHeaderMenus}>
                <button className="p-panel-header-icon p-link mr-2">
                  <Link
                    to={`/modules/messaging/${post.id}`}
                    className="hover:text-blue-800"
                  >
                    <span className="pi pi-eye"></span>
                  </Link>
                </button>
                {user?.id === post.user.id && (
                  <button
                    className="p-panel-header-icon p-link mr-2 hover:text-red-800"
                    onClick={(e) => {
                      confirmPopup({
                        target: e.currentTarget,
                        message: `${t("forum:confirm_delete")}`,
                        icon: "pi pi-exclamation-triangle",
                        defaultFocus: "accept",
                        acceptClassName: "p-button-danger text",
                        accept: () => deletePost(post.id),
                      });
                    }}
                  >
                    <span className="pi pi-trash"></span>
                  </button>
                )}
                {options.togglerElement}
              </div>
          </div>
    );
  };
  return (
    <div>
      <Panel headerTemplate={headerTemplate} footerTemplate={footerTemplate}>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          ></div>
          {/* <PostEditor
            className={styles.readOnlyEditor}
            value={post.content}
            readonly={true}
          /> */}
        </div>
      </Panel>
    </div>
  );
};

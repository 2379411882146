import {FC, useState} from 'react';
import { Language } from './types';

type DropdownProps = {
  languages: Array<Language>;
  selectedLanguage: string;
  onSelect: (language: Language) => void;
};

const DropDown: FC<DropdownProps> = ({ languages, onSelect, selectedLanguage }) => {

  // const [selectedLanguage, setSelectedLanguage] = useState('English');

  // const handleSelect = (language: string) => {
  //   setSelectedLanguage(language);
  //   onSelect(language);
  // };

  return (
    <div className="dropdown-container">
      <button className="btn-drop">
      {selectedLanguage}
      </button>
      <div className="btn-drop-items border">
        <ul className="btn-item-list">
          {languages.map((lang) => (
              <li key={lang.id} onClick={() => onSelect(lang)}>
                {lang.name}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default DropDown

import AbstractMapService from './MapService';
import { MapConfig } from './MapConfig';

interface MapLocation {
    name?:string;
    city?: string;
    state?: string;
    latitude: number;
    longitude: number;
}
export default class OpenStreetMapService extends AbstractMapService {
    private config: MapConfig;
    constructor(config: MapConfig) {
        super();
        this.config = config;
    }
    async getCurrentLocation(): Promise<MapLocation | null> {
      try {
        const position = await this.getCurrentPosition();
        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      } catch (error) {
        console.error('Error fetching current location:', error);
        return null;
      }
    }
    async searchLocation(placeName: string): Promise<MapLocation | null> {
      try {
        const response = await this.fetchJson(
          `${this.config.locationSearchUrl}${encodeURIComponent(
            placeName
          )}`
        );
  
        if (response && response.length > 0) {
          const { lat, lon } = response[0];
          return {
            latitude: parseFloat(lat),
            longitude: parseFloat(lon),
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error searching location:', error);
        return null;
      }
    }
    async fetchPlaceName(latitude: number, longitude: number): Promise<MapLocation | null> {
        try {
          const response = await this.fetchJson(`${this.config.locationNameSearchUrl}${latitude}&lon=${longitude}`);
          if (response && response.display_name) {
            return {
                name: response.display_name,
                state: response.state,
                city: response.city,
                latitude,
                longitude,
            }
          } else {
            return null;
          }
        } catch (err) {
          console.error('Error fetching place name:', err);
          return null;
        }
      }
      async getLocation(): Promise<{latitude: number, longitude: number, accuracy: number} | null> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy,
                        });
                    },
                    (error) => {
                        reject(this.handleGeolocationError(error));
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0,
                    }
                );
            } else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    }
  }
import { Form } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { get } from "../../../apiClient";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { withTranslation } from "react-i18next";

const FiltersComponent = ({
  filterNames,
  searchFilters,
  fetchMembers,
  t,
}: any): any => {
  const [name, setName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [nationalId, setNationalId] = useState<string>("");
  const [dateJoined, setDateJoined] = useState<any>(undefined);
  const [filters, setFilters] = useState<any>({});

  const [districts, setDistricts] = useState<any>([]);
  const [district, setDistrict] = useState<any>([]);

  const [tas, setTas] = useState<any>([]);
  const [ta, setTa] = useState<any>([]);

  const [epas, setEpas] = useState<any>([]);
  const [epa, setEpa] = useState<any>([]);

  const [sections, setSections] = useState<any>([]);
  const [section, setSection] = useState<any>([]);

  const [cooperative, setCooperative] = useState<any>([]);

  const [hideShowLabel, setHideShowLabel]: any = useState("Show more filters");
  const [isShowMore, setIsShowMore]: any = useState(false);

  const toast: any = useRef(null);

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await get("districts");
      const filteredDistricts = response?.data?.map((district: any) => {
        return district;
      });
      setDistricts(filteredDistricts);
    };
    fetchDistricts();
  }, []);

  const handleHideShowMore = () => {
    !isShowMore
      ? setHideShowLabel(t("show_less"))
      : setHideShowLabel(t("show_more"));
    setIsShowMore(!isShowMore);
  };

  const handleNameInput = async (data: any) => {
    const { value, name } = data?.target;
    if (name == "full_name") setName(value);
    if (name == "phone_number") setPhoneNumber(value);
    if (name == "created") setDateJoined(value);
    setFilters({ ...filters, [name]: value });
    filterNames({ ...filters, [name]: value });
  };

  const handleDistrictInputChange = async (data: any) => {
    const response = await get("districts", {}, `${data[0]?.id}/tas`);
    const filteredResponse = response?.data?.map((item: any) => {
      return item;
    });
    setDistrict(data);
    setTas(filteredResponse);
    setTa([]);
    setEpa([]);
    setSection([]);
    handleNameInput({ target: { name: "district_id", value: data[0]?.id } });
    setCooperative("");
  };

  const handleTaInputChange = async (data: any) => {
    const response = await get("tas", {}, `${data[0]?.id}/epas`);
    const filteredResponse = response?.data?.map((item: any) => {
      return item;
    });
    setTa(data);
    setEpas(filteredResponse);
    setEpa([]);
    setSection([]);
    handleNameInput({ target: { name: "ta_id", value: data[0]?.id } });
    setCooperative("");
  };

  const handleEpaInputChange = async (data: any) => {
    const response = await get("epas", {}, `${data[0]?.id}/sections`);
    const filteredResponse = response?.data?.map((item: any) => {
      return item;
    });

    setEpa(data);
    setSections(filteredResponse);
    setSection([]);
    handleNameInput({ target: { name: "epa_id", value: data[0]?.id } });
    setCooperative("");
  };

  const handleSectionInputChange = async (data: any) => {
    setSection(data);
    handleNameInput({ target: { name: "section_id", value: data[0]?.id } });
    setCooperative("");
  };

  const handleCooperativeInputChange = async (data: any) => {
    const { name, value } = data.target;
    setCooperative({ ...cooperative, [name]: value });
  };

  const clearButton = (): void => {
    setFilters({});
    setName("");
    setPhoneNumber("");
    setDateJoined(new Date(""));
    setDistrict([]);
    setTa([]);
    setEpa([]);
    setSection([]);
    fetchMembers({});
  };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Form.Group>
        <div className="row">
          <div className="col-lg-2">
            <Form.Label className="form-labels">{t("name")}</Form.Label>
            <Form.Control
              type="text"
              name="full_name"
              placeholder={`${t("name")}`}
              value={name}
              onChange={handleNameInput}
            />
          </div>
          <div className="col-lg-2">
            <Form.Label className="form-labels">
              {t("phone_number")}
            </Form.Label>
            <Form.Control
              type="text"
              name="phone_number"
              value={phoneNumber}
              placeholder={`${t("phone_number")}`}
              onChange={handleNameInput}
            />
          </div>
          <div className="col-lg-2">
            <Form.Label className="form-labels">
              {t("date_created")}
            </Form.Label>
            <Calendar
              className="date-joined"
              placeholder="YYYY-MM-DD"
              name="created"
              value={dateJoined}
              onChange={handleNameInput}
            />
          </div>
          {isShowMore ? (
            <div className="col-lg-2">
              <Form.Label className="form-labels">
                {t("district")}
              </Form.Label>
              <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleDistrictInputChange}
                options={districts}
                selected={district}
                placeholder={t("select")}
              />
            </div>
          ) : (
            ""
          )}

          {isShowMore ? (
            <div className="col-lg-2">
              <Form.Label className="form-labels">
                {t("traditional_authority")}
              </Form.Label>
              <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleTaInputChange}
                options={tas}
                placeholder={t("select")}
                selected={ta}
              />
            </div>
          ) : (
            ""
          )}

          {isShowMore ? (
            <div className="col-lg-2">
              <Form.Label className="form-labels">
                {t("epa")}
              </Form.Label>
              <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleEpaInputChange}
                options={epas}
                placeholder={t("select")}
                selected={epa}
              />
            </div>
          ) : (
            ""
          )}

          {isShowMore ? (
            <div className="col-lg-2">
              <Form.Label className="form-labels">
                {t("section")}
              </Form.Label>
              <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleSectionInputChange}
                options={sections}
                placeholder={t("select")}
                selected={section}
              />
            </div>
          ) : (
            ""
          )}
          {isShowMore ? (
            <div className="col-lg-2">
              <Form.Label className="form-labels">
                {t("cooperative")}
              </Form.Label>
              <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                onChange={handleCooperativeInputChange}
                options={sections}
                placeholder={t("select")}
                selected={section}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-lg-2">
            <Form.Label className="form-labels">
              {t("filter_actions")}
            </Form.Label>
            <div className="module-wrapper">
              <div className="reset-button-container">
                <Button
                  className="filter-buttons search-button"
                  style={{width:'100px'}}
                  label={t("common:reset_button")}
                  severity="secondary"
                  onClick={clearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </Form.Group>
      <div className="reset-button-container">
        <a
          className="filter-buttons search-button"
          onClick={handleHideShowMore}
          href="#"
        >
          {hideShowLabel}
        </a>
      </div>
    </>
  );
};

// high order component {withTranslation} HOC
export default withTranslation("common")(FiltersComponent);

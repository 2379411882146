import { Button } from "primereact/button";
import { FC } from "react";

type ActionButtonProps = {
    label?: string;
    icon?: string;
    severity: 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help' | undefined;
    onClick: (e: any) => void;
}
const ActionButton: FC<ActionButtonProps> = ({ onClick, label, icon, severity ='secondary' }) => {
  return (
    <div>
      <Button icon={icon} onClick={onClick} label={label}   severity={severity} rounded />
    </div>
  );
};

export default ActionButton;    

import "../../App.css";
import MembersListComponent from "../../components/MembersComponents/MembersListComponent";
import { get, remove } from "../../apiClient";
import { withTranslation } from "react-i18next";
import { REGISTER_ACTIONS } from "./constants";
import ActionButton from "./components/ActionButton";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FiltersComponent from "../../components/SharedComponents/FiltersComponents/FiltersComponent";
import { Skeleton } from "primereact/skeleton";
const headers: any = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

interface MembersAppModuleProps {
  t: any;
}

const MembersAppModule = ({t}: MembersAppModuleProps) => {
  const [members, setMembers]: any = useState([]);
  const [originalMemberData, setOriginalMemberData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [numberOfMembers, setNumberOfMembers] = useState(0);

  const navigate = useNavigate();

  const items = [
    { label: "Farmers" },
    { label: "Manage", url: "/modules/members" },
    { label: "Register", url: "/modules/members/create" },
  ];

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async (params: any = {}) => {
    setIsLoading(true);
    const response = await get(
      `members${buildFetchMeberParamsString(params)}`,
      headers
    );
    const members = response?.data?.data.map((member: any) => {
      return {
        id: member.id,
        full_name: `${member.first_name} ${member.last_name}`,
        dob: member.dob,
        location: `${member?.traditional_authority?.name}, ${member?.village?.name}`,
        district: member?.district?.name,
        phone_number: member.phone_number,
        email: member.email,
        gender: member.gender,
        epa: member?.epa?.name,
        district_id: member?.district?.id,
        epa_id: member?.epa?.id,
        ta_id: member?.traditional_authority?.id,
        section_id: member?.section?.id,
        cooperative_id: member?.cooperative?.id,
        created: member.createdAt,
        member,
      };
    });
    setMembers(members);
    setOriginalMemberData(members);
    setNumberOfMembers(members.length);
    setIsLoading(false);
  };

  const buildFetchMeberParamsString = (data: any = {}) => {
    if (Object.values(data).length === 0) return "";

    const params = new URLSearchParams();

    const { param, value, action }: any = data;

    action === "append" ? params.append(param, value) : params.delete(param);

    return params.toString().length === 0 ? "" : `?${params.toString()}`;
  };

  const catchFilters = (filters: any) => {
    fetchMembers(filters);
  };

  function filterSearch(data: [], filters: any) {
    setIsLoading(true);
    const cleanFilters = removeUndefinedProperties(filters);
    if (Object.entries(cleanFilters).length === 0) fetchMembers();
    let response: any[] = [];
    response = searchFilter(data, cleanFilters);
    if (response.length == 0 && Object.entries(cleanFilters).length > 0) {
      response = searchFilter(originalMemberData, cleanFilters);
    }
    setMembers(response);
    setIsLoading(false);
  }

  function removeUndefinedProperties(obj: any) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined || obj[key] == "") {
        delete obj[key];
      }
    });
    return obj;
  }

  function searchFilter<T extends any>(data: any[], filters: any): T[] {
    return data.filter((item) => {
      for (let key in filters) {
        const filterValue = filters[key];
        let itemValue: any = item[key];

        if (filterValue instanceof Date) {
          itemValue = new Date(itemValue);
          if (
            isNaN(itemValue.getTime()) ||
            !isDateEqual(filterValue, itemValue)
          ) {
            return false;
          }
        } else {
          if (
            filterValue &&
            filterValue !== "" &&
            (itemValue == null ||
              !String(itemValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase()))
          ) {
            return false;
          }
        }
      }
      return true;
    });
  }

  function isDateEqual(datePicked: any, dateJoined: any) {
    return (
      datePicked.getFullYear() === dateJoined.getFullYear() &&
      datePicked.getMonth() === dateJoined.getMonth() &&
      datePicked.getDate() === dateJoined.getDate()
    );
  }

  async function onRemove(member: any) {
    setIsLoading(true);
    const response = await remove(`members`, member.id, headers);
    console.log("Delete Response", response);
    if (response.status === 200) {
      fetchMembers();
    }
    setIsLoading(false);
  }

  return (
    <div>
      {/* <BreadCrumb model={items} home={home} /> */}
      <div className="p-5 silver-line">
        <div>
          <h1 className="text-4xl font-medium text-900 mb-3">
            {t("farmers_members_header")}
          </h1>
          <div className="font-medium text-500 mb-3">
            <i className="pi pi-users mr-2"></i>
            <span>{numberOfMembers}</span>
          </div>

          <div className="flex gap-2 justify-content-end">
            {REGISTER_ACTIONS.map((action: any, index: number) => {
              return (
                <ActionButton
                  key={index}
                  label={t(action.label)}
                  icon={action.icon}
                  severity={action.severity}
                  onClick={() => navigate(action.url)}
                />
              );
            })}
          </div>
          <div className="mt-5">
            <FiltersComponent
              filterNames={(filters: any) => {
                filterSearch(members, filters);
              }}
              searchFilters={catchFilters}
              fetchMembers={fetchMembers}
            />
            <hr />
            {isLoading ? (
              <div>
                <Skeleton
                  height="4rem"
                  className="mb-2"
                  borderRadius="16px"
                ></Skeleton>
                <Skeleton
                  height="4rem"
                  className="mb-2"
                  borderRadius="16px"
                ></Skeleton>
                <Skeleton
                  width="40%"
                  height="4rem"
                  borderRadius="16px"
                ></Skeleton>
              </div>
            ) : (
              <MembersListComponent
                members={members}
                // translations={indexTranslations}
                setNumberOfMembers={setNumberOfMembers}
                onRemove={onRemove}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(MembersAppModule);



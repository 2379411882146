import axios from 'axios';

interface MapLocation {
    latitude: number;
    longitude: number;
}

export default abstract class AbstractMapService {
    abstract getCurrentLocation(): Promise<MapLocation | null>;
    abstract searchLocation(placeName: string): Promise<MapLocation | null>;
    abstract fetchPlaceName(latitude: number, longitude: number): Promise<MapLocation | null>;
    abstract getLocation(showPosition: (position: GeolocationPosition) => void, showError: (error: GeolocationPositionError) => void): void;

    protected async getCurrentPosition(): Promise<GeolocationPosition> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            });
        });
    }

    protected async fetchJson(url: string): Promise<any> {
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    protected handleGeolocationError(error: GeolocationPositionError): string {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                return "User denied the request for Geolocation.";
            case error.POSITION_UNAVAILABLE:
                return "Location information is unavailable.";
            case error.TIMEOUT:
                return "The request to get user location timed out.";
            default:
                return "An unknown error occurred.";
        }
    }
}
import { useContext, useState, useImperativeHandle, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { RegistrationContext } from '../../MembersComponents/MembersRegisterComponent'
import { withTranslation } from 'react-i18next';

const ContactsComponent = forwardRef(({ onChange, t }: any, ref : any): any => {

    const data: any = useContext(RegistrationContext)
    const handleInputChange = async (event: any) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    const [errors, setErrors]: any = useState({});

    const validateFrom = () => {
        const newErrors: any = {};

        if (!data?.email?.trim()) {
            newErrors.email = 'Email is required';
        }

        if (!data?.phone_number?.trim()) {
            newErrors.phone_number = 'Phone Number is required!';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    useImperativeHandle(ref, () => ({
        validateFrom,
    }));

    return (
        <>
            <Form.Group controlId="formStep1">

                <Form.Label>{`${t("common:email_label")}`} *</Form.Label>
                <Form.Control
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={handleInputChange}
                    className='input'
                />

                <p className='errorMessage'> {errors.email && <span>{errors.email}</span>} </p>
                <hr />

                <Form.Label>{`${t("common:phone_number")}`} *</Form.Label>
                <Form.Control
                    type="text"
                    name="phone_number"
                    value={data.phone_number}
                    onChange={handleInputChange}
                    className='input'
                />

                <p className='errorMessage'> {errors.phone_number && <span>{errors.phone_number}</span>} </p>
                <hr />

                <Form.Label>{`${t("common:other_phone_number")}`}</Form.Label>
                <Form.Control
                    type="text"
                    name="secondary_phone_number"
                    value={data.secondary_phone_number}
                    onChange={handleInputChange}
                    className='input'
                />

            </Form.Group>
        </>
    );
});

export default withTranslation(["eRegistration", "common"])(ContactsComponent);
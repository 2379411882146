import { InputTextarea } from "primereact/inputtextarea";
import { FC, useState } from "react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { ForumComment, Pagination } from "../../types";
import { User } from "../../context/types";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";

type CommentProps = {
  comments: ForumComment[];
  user: User;
  pagination: Pagination;
  hanleAddComment: (comment: string) => void;
  handleOnPageChange: (event: PaginatorPageChangeEvent) => void;
};

const Comment: FC<CommentProps> = ({
  comments,
  user,
  pagination,
  hanleAddComment,
  handleOnPageChange,
}) => {
  const [commentText, setCommentText] = useState<string>("");
  const [error, setError] = useState("");
  const handleChange = (e: any) => {
    setCommentText(e.target.value);
    setError("");
  };
  const handleSubmitComment = () => {
    if (!commentText) {
      setError("Comment cannot be empty");
      return;
    }
    hanleAddComment(commentText);
    setCommentText("");
    // add comment
  };
  return (
    <div className="ps-4">
      <div className="flex gap-4">
        <Avatar label={user.first_name[0]} size="normal" shape="circle" />
        <InputTextarea
          className="w-100"
          rows={2}
          value={commentText}
          onChange={handleChange}
          placeholder="Join the discussion by making a comment"
        />
        {error && <p className="text-danger">{error}</p>}
      </div>
      <div className="flex justify-content-end mt-2">
        <Button className="w-auto rounded f" onClick={handleSubmitComment}>
          Comment
        </Button>
      </div>

      <div className="mt-3 ml-4 flex flex-column gap-2">
        {comments.map((comment, index) => {
          return <CommentRow key={index} comment={comment} />;
        })}

        <Paginator
          first={pagination.offset}
          rows={pagination.limit}
          totalRecords={pagination.count}
          onPageChange={handleOnPageChange}
          template={{ layout: "PrevPageLink CurrentPageReport NextPageLink" }}
        />
      </div>
    </div>
  );
};

export default Comment;

const CommentRow: FC<{ comment: ForumComment }> = ({ comment }) => {
  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div className="flex align-items-center gap-2">
          <Avatar
            label={comment.user.first_name[0]}
            size="normal"
            shape="circle"
          />
          <span className="font-bold text-sm">{comment.user.name}</span>
        </div>
        <div>
          <button className="p-panel-header-icon p-link mr-2">
            <span className="pi pi-clock"></span>
          </button>
          <button
            className="p-panel-header-icon p-link mr-2"
            // onClick={(e) => configMenu?.current?.toggle(e)}
          >
            <span className="pi pi-trash"></span>
          </button>
          {options.togglerElement}
        </div>
      </div>
    );
  };

  return (
    <Panel headerTemplate={headerTemplate} toggleable={false}>
      <p className="m-0">{comment.comment}</p>
    </Panel>
  );
};

import { FC, useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MapConfig } from './MapConfig';
import MapReadingsComponent from './MapReadingsComponent';
import OpenStreetMapService  from './OpenStreetMapService';
import GoogleMapService from './GoogleMapService';
import { Area } from "../../../types";

interface MapLocation {
    config?: MapConfig;
    latitude?: number | null;
    longitude?: number | null;
    accuracy?: number | null;
    name?: string | null;
    type?: string | null;
    mark_value?: MapMarkStatus | null;
    location_area?: LocationArea | null;
    invokeCancelMapMarker?: (isCancel: boolean) => void;
}

interface LocationArea {
    district: Area;
    ta: Area;
    village: Area;
    fullname: string;
}

enum MapMarkStatus {
    Marked = 'success',
    Pending = 'warning',
    Unmarked = 'danger'
}
const DefaultIcon = L.icon({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

L.Marker.prototype.options.icon = DefaultIcon;

const GeoLocationBaseComponent: FC<MapLocation> = ({ latitude, longitude, name, type, config, mark_value, location_area, invokeCancelMapMarker }: any) => {
    const [draggedLocation, setDraggedLocation] = useState<MapLocation>({ latitude: null, longitude: null, accuracy: null });
    const [currentPosition, setCurrentPosition] = useState<MapLocation>({ latitude: null, longitude: null, accuracy: null });
    const [zoomLevel, setZoomLevel] = useState<number>(7);
    const [error, setError] = useState<string | null>(null);
    const [placeName, setPlaceName] = useState<string>('');
    const [_, setCurrentDistrict] = useState<string | null>(null);
    const [__, setCurrentState] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isGoogleMapSet, setIsGoogleMapSet] = useState<boolean>(false);
    const [currentPlaceName, setCurrentPlaceName] = useState<string>('');
    const [resultsFor, setResultsFor] = useState<string>('');
    const osmService = new OpenStreetMapService(config);
    const googleService = new GoogleMapService(config);

    useEffect(() => {
        if(config.service === 'GoogleMaps' && config.apiKey){
            setIsGoogleMapSet(true);
        }
        if(location_area){
            const area: string = `${location_area.district.name}, Malawi`
            searchLocation(area);
            const resultFor: string = `${location_area.district.name}, Malawi`;
            setResultsFor(resultFor);
        }else{
            fetchCurrentLocation();
        }
        
    }, []);

    const fetchCurrentLocation = async () => {
        setIsLoading(true);
        try {
            const location = isGoogleMapSet ? await googleService.getLocation() : await osmService.getLocation();
            if (location) {
                setDraggedLocation(location);
                setCurrentPosition(location);
                setZoomLevel(13);
                const locationData: any = isGoogleMapSet ? await googleService.fetchPlaceName(location.latitude, location.longitude) : await osmService.fetchPlaceName(location.latitude, location.longitude);
                setResultsFor(locationData.name);
                setCurrentPlaceName(locationData.name);
                setError(null);
            }
        } catch (err: any) {
            setError(err.message);
        }
        setIsLoading(false);
    };

    const updatePosition = (event: L.DragEndEvent) => {
        setIsLoading(true)
        const marker = event.target as L.Marker;
        const position = marker.getLatLng();
        const newAccuracy = calculateAccuracy(position.lat, position.lng);
        const newLocation = {
            latitude: position.lat,
            longitude: position.lng,
            accuracy: newAccuracy,
        };
        setDraggedLocation(newLocation);
        setCurrentPosition(newLocation);
        fetchPlaceName(position.lat, position.lng);
        setIsLoading(false)
    };

    const calculateAccuracy = (latitude: number, longitude: number): GLfloat => {
        const refLatitude = 15.75086;
        const refLongitude = 34.99512;
        const toRadians = (degree: number) => (degree * Math.PI) / 180;
        const R = 6371e3;
        const φ1 = toRadians(refLatitude);
        const φ2 = toRadians(latitude);
        const Δφ = toRadians(latitude - refLatitude);
        const Δλ = toRadians(longitude - refLongitude);
        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const savePosition = () => {
        setIsLoadingSave(true);
        console.log(draggedLocation);
        setIsLoadingSave(false);
    };

    const searchLocation = async (searchInput: string = "") => {
        setIsLoading(true)
        try {
            const name = placeName != "" ? `${placeName}` : searchInput;
            const location: any = isGoogleMapSet ? await googleService.searchLocation(name) : await osmService.searchLocation(name);
            setResultsFor(name);
            if (location) {
                setDraggedLocation(location);
                setCurrentPosition(location);
                setZoomLevel(13);
                fetchPlaceName(location.latitude, location.longitude);
                setError(null);
            } else {
                setDraggedLocation({});
                setCurrentPosition({});
                setCurrentPlaceName("");
                setZoomLevel(13);
                setError("Location not found.");
            }
            setPlaceName("");
        } catch (err) {
            setError("Failed to fetch location data.");
        }
        setIsLoading(false)
    };

    const fetchPlaceName = async (latitude: number, longitude: number) => {
        try {
            const location: any = isGoogleMapSet ? await googleService.fetchPlaceName(latitude, longitude) : await osmService.fetchPlaceName(latitude, longitude);
            if (location && location.name) {
                setCurrentDistrict(location.city);
                setCurrentState(location.state);
                setCurrentPlaceName(location.name);
                setResultsFor(location.name);
            } else {
                setCurrentPlaceName('');
            }
        } catch (err) {
            setCurrentPlaceName('');
        }
    };

    const DynamicMap = () => {
        const map = useMap();
        if (currentPosition.latitude && currentPosition.longitude) {
            map.setView([currentPosition.latitude, currentPosition.longitude], zoomLevel);
        }
        return null;
    };

    const resetLocation = () : void => {
        setPlaceName(""); 
        searchLocation(`${location_area.district.name}, Malawi`);
    }

    const cancelMapMarker = (): void => {
        invokeCancelMapMarker(false);
    }

    return (
        <div className='row' style={{ fontFamily: 'Arial, sans-serif', alignItems: 'center', justifyContent: 'center', height: '0vh' }}>
            <div className="col-md-12">
                <div className='row'>
                    <div className="col-md-12">
                        <div>
                            <label htmlFor="">Cancel</label>
                        </div>
                        <Button onClick={cancelMapMarker} icon="pi pi-times" rounded outlined severity="danger" aria-label="Cancel" style={{ height: '30px', fontWeight: 'normal' }} />
                        <hr />
                    </div>
                    <div className="col-md-12">
                        <div>
                            <InputText value={placeName}
                                placeholder={`Enter Location Name`}
                                onChange={(e) => setPlaceName(e.target.value)}
                                id="place"
                                aria-describedby="place-help"
                                style={{height: '30px', fontSize: '11px'}}
                            />
                            <Button loading={isLoading} style={{ marginLeft: '1%', height: '30px', fontWeight: 'normal', fontSize: '11px' }} onClick={() => {searchLocation("")}} label="Search Location" type="button" className="p-button-outlined" />
                            <Button loading={isLoading} style={{ marginLeft: '1%', height: '30px', fontWeight: 'normal', fontSize: '11px' }} onClick={fetchCurrentLocation} label="Get My Location" type="button" className="p-button-outlined" severity="help" />
                            <Button loading={isLoading} style={{ marginLeft: '1%', height: '30px', fontWeight: 'normal', fontSize: '11px' }} onClick={() => { resetLocation() } } label="Reset To Selected" type="button" className="p-button-outlined" />
                            <div style={{marginTop: '0.5%', fontSize: '12px'}}>Showing results for "{resultsFor}"</div>
                        </div>
                    </div>
                </div>
                <hr />
               
                    <div className='row'>
                        <div className="col-md-5">
                        <div style={ { marginTop: '1%', marginBottom: '0%' } }>
                            <label className="styled-link" style={{ marginRight: '0.5%' }} >Google Maps Readings</label>
                            <img
                                alt="profile"
                                width="3.5%"
                                height="3.5%"
                                src={process.env.PUBLIC_URL + `/icons/map_marker_1.png`}
                                style={{ borderRadius: "2%" }}
                            />
                        </div>
                            <MapReadingsComponent
                                accuracy={currentPosition.accuracy}
                                latitude={currentPosition.latitude}
                                longitude={currentPosition.longitude}
                                name={currentPlaceName}
                            />
                        </div>
                        <div className="col-md-2" style={{ marginTop: '0%' }}>
                            <label className="styled-link" style={{ marginLeft: '7%' }}>Mark Map</label>
                            <div className={`hoverable-map-img ${isLoading || isLoadingSave ? 'disable-merge-button' : ''}`}>
                                <img
                                    alt="profile"
                                    width="100%"
                                    height="42px"
                                    src={process.env.PUBLIC_URL + `/icons/double_arrow_3.png`}
                                    style={{ cursor: "pointer", borderRadius: "10%", padding: "5%" }}
                                    onClick={savePosition}
                                />
                                { isLoadingSave && (<ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="6" fill="var(--surface-ground)" animationDuration=".5s" />)}
                            </div>
                            <p style={{ fontSize: '11px', marginTop: '0%' }}>Google to DAES</p>
                        </div>
                        <div className="col-md-5">
                            <MapReadingsComponent
                                latitude={latitude}
                                longitude={longitude}
                                name={name}
                                type="DEAS Map Readings"
                                mark_value={mark_value}
                                location_area={location_area}
                                locationFilters={searchLocation}
                            />
                        </div>
                    </div>
                <hr />
                {error && error && (
                    <div id="location" style={{ marginTop: '20px', fontSize: '18px' }}>
                        <p>{error}</p>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-12">
                        {!error && currentPosition.latitude && currentPosition.longitude && (
                            <>
                                <MapContainer center={[-13.2543, 34.3015]} zoom={zoomLevel} style={{ height: '500px', width: '100%' }}>
                                    <DynamicMap />
                                    {config.service === 'OpenStreetMap' && config.tileLayerUrl && (
                                        <TileLayer
                                            url={config.tileLayerUrl}
                                            attribution={config.attribution || ''}
                                        />
                                    )}
                                    {config.service === 'GoogleMaps' && config.apiKey && (
                                        <TileLayer
                                            url={config.tileLayerUrl}
                                            attribution={config.attribution || '© Google'}
                                        />
                                    )}
                                    <Marker
                                        position={[currentPosition.latitude, currentPosition.longitude]}
                                        draggable={true}
                                        eventHandlers={{
                                            drag: (event) => {
                                                const marker = event.target as L.Marker;
                                                const position = marker.getLatLng();
                                                const newAccuracy = calculateAccuracy(position.lat, position.lng);
                                                setCurrentPosition({
                                                    latitude: position.lat,
                                                    longitude: position.lng,
                                                    accuracy: newAccuracy,
                                                });
                                            },
                                            dragend: updatePosition
                                        }}
                                        icon={DefaultIcon}
                                    >
                                        <Popup>
                                            You are here: <br /> Latitude: {currentPosition.latitude}, <br /> Longitude: {currentPosition.longitude}
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default GeoLocationBaseComponent;

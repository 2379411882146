export const MODULE_TRANSLATION_KEYS = {
  members: {
    key: "members_module",
    transKey: "side_nav",
  },
  reports: {
    key: "reports_module",
    transKey: "side_nav",
  },
  advisories: {
    key: "advisory_module",
    transKey: "side_nav",
  },
  messaging: {
    key: "messaging_module",
    transKey: "side_nav",
  },
  settings: {
    key: "settings_module",
    transKey: "side_nav",
  }
};

import { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { classNames } from "primereact/utils";

type PostEditorProps = {
  value?: any;
  className?: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
};
const PostEditor: FC<PostEditorProps> = ({
  className,
  value,
  readonly,
  onChange,
}) => {
  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ["blockquote", "code-block"],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ direction: "rtl" }], // text direction

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
  ];

  const modules = {
    toolbar: readonly ? [] : toolbarOptions,
  };
  return (
    <ReactQuill
      className={classNames("editor", className)}
      modules={modules}
      theme="snow"
      value={value}
      readOnly={readonly}
      onChange={onChange}
    />
  );
};

export default PostEditor;

import { InputText } from "primereact/inputtext";
import { FC, FocusEventHandler } from "react";
type TextInputProps = {
  label: string;
  value?: string;
  error?: string;
  type?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput: FC<TextInputProps> = ({
  label,
  value,
  name,
  error,
  type = "text",
  onChange,
  onBlur,
}) => {
  return (
    <div className="flex flex-column gap-1">
      <label htmlFor={label}>{label}</label>
      <InputText
        className="p-inputtext-sm"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        type={type}
      />
      {error && <span className="text-sm text-danger">{error}</span>}
    </div>
  );
};

export default TextInput;
